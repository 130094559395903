import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getCurrentCount, removeMeWaitlistQueue } from "../api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../assests/css/style.css";
import { timeConverter } from "../services/utils";
import moment from "moment";
import { ReactComponent as Clock } from "../assests/images/clock.svg";
import { ReactComponent as CharacterIcon } from "../assests/images/characterIcon.svg";
import { colorStyles } from "../assests/css/color";
import { HeaderMock } from "../component/HeaderMock";
// moment.updateLocale('en', {
//   invalidDate : String
// });
const useStyles = makeStyles((theme) => ({
  header: {
    color: "#fff",
    display: "flex",
    fontSize: "20px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: colorStyles.primaryColor,
    backgroundColor: colorStyles.primaryBackgroundColor,
    width: "100%",
    height: 80,
  },
  logo: {
    backgroundColor: "#0386ee",
    backgroundColor: "#07174B",
    width: "100%",
    height: 80,
    "& img": {
      width: 80,
    },
    "& .goBack": {
      // textAlign: "start",
      position: "absolute",
      left: "2px",
      "& span": {
        color: "#0386ee",
      },
    },
  },
  root: {
    textAlign: "-webkit-center",
    // width: "100%",
    // height: 500,
    // padding: 10,
    fontFamily: "'Manrope', sans-serif",
  },
  main: {
    // boxShadow: "0px 0px 1px #262F56",
    borderRadius: 10,
    "& #qr-gen": {
      display: "none",
    },
    // [theme.breakpoints.down("sm")]: {
    //   width: "90%",
    // },
  },
  heading: {
    backgroundColor: "#0386ee",
    height: "70px",
    borderRadius: "10px",
    display: "flex",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
    width: 360,
    [theme.breakpoints.up("md")]: {
      width: 400,
      padding: "0px 30px",
    },
    "& span": {
      margin: "13px 0px",
      padding: "10px",
      borderRadius: "10px",
      background: "#aaed07",
      fontWeight: 500,
      color: "#fff",
    },
    "& .waitingTimeLoader": {
      display: "flex",
      alignItems: "center",
      "& > span": {
        background: "none",
      },
    },
  },
  innerSec: {
    display: "flex",
    margin: 25,
    height: "40px",
    justifyContent: "center",
    border: "1px solid #0386ee",
    width: "max-content",
    padding: "20px",
    alignItems: "center",
    borderRadius: "10px",
    "& p": {
      margin: "0px 15px",
    },
  },
  innerSecCurrent: {
    display: "flex",
    margin: 25,
    height: "40px",
    background: "#04ee50",
    color: "#fff",
    justifyContent: "center",
    border: "1px solid #0386ee",
    width: "max-content",
    padding: "20px",
    alignItems: "center",
    borderRadius: "10px",
    "& p": {
      margin: "0px 15px",
    },
  },
  waitlistBtn: {
    margin: 20,
    marginTop: 80,
    "& Button": {
      backgroundColor: "#0386ee",
      color: "#fff",
      borderRadius: "10px",
      padding: "0px 30px",
      height: "50px",
    },
    "& Button:hover": {
      color: "#0386ee",
      border: "1px solid #0386ee",
      backgroundColor: "#fff",
    },
  },
  removeWaitlistBtn: {
    margin: 20,
    "& Button": {
      color: "white",
      background: "red",
      border: "1px solid red",
      height: "50px",
      borderRadius: "10px",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "#e74d4d",
      border: "1px solid #e74d4d",
      boxShadow: "none",
    },
  },
}));

const RegistrationSuccess = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const count = location.state && location.state.count;
  const confirmTimeSlot = location.state && location.state.confirmTimeSlot;
  const queueName = location.state && location.state.queueName;

  let { id } = useParams();
  id = atob(id);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userRegistrationDateTime, setUserRegistrationDateTime] = useState("");
  const [userCount, setUserCount] = useState(count);
  const [estimatedWaitTime, setEstimatedWaitTime] = useState(0);
  const [waitDuration, setWaitDuration] = useState(0);
  const [queueDuration, setQueueDuration] = useState(5);
  const [queueOpeningTime, setQueueOpeningTime] = useState(null);
  const [userMessage, setUserMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [isReCheck, setIsCheck] = useState(true);
  const [deviceName, setDeviceName] = useState("");
  const [audioMessage, setAudioMessage] = useState("");
  const [audioUrl, setAudioUrl] = useState("");

  let ws;
  let connected = false;
  useEffect(() => {
    setIsLoading(true);
    fetchCurrentCount();
  }, []);

  // useEffect(() => {
  //   if (isReCheck) {
  //     const interval = setInterval(() => {
  //       fetchCurrentCount();
  //       calculateWaitDuration();
  //       getUserMessage();
  //     }, 1000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // });
  useEffect(() => {
    console.log(
      "location.state && location.state.count",
      location.state && location.state.confirmTimeSlot
    );
  }, []);

  const fetchCurrentCount = () => {
    setIsCheck(false);
    getCurrentCount(id, userRegistrationDateTime)
      .then((res) => {
        setIsCheck(true);
        setCurrentNumber(res.currentNum);
        setEstimatedWaitTime(res.appointmentTime);
        setQueueDuration(res.duration);
        setQueueOpeningTime(res.openingTime);
        calculateWaitDuration();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const calculateWaitDuration = () => {
    if (userCount === currentNumber) {
      setWaitDuration(0);
    } else {
      let waitingDate = moment(estimatedWaitTime, "hh:mm a");
      let waitMinutes = waitingDate.diff(moment(), "minutes");
      let minimumWaitTime = (userCount - (currentNumber + 1)) * queueDuration;
      setWaitDuration(
        waitMinutes < minimumWaitTime ? minimumWaitTime : waitMinutes
      );
    }
  };

  const getUserMessage = () => {
    const defaultMessage = `You are enrolled in line at ${id && id.split("_")[2]
      }.`;
    const queueStartMessage = `You are at position: ${userCount}. ${queueOpeningTime ? `Queue will start at ${queueOpeningTime}` : ""
      }`;
    const waitingTimeMessage = `Your estimated waiting time is ${isNaN(waitDuration) || waitDuration <= 0 ? 0 : timeConverter(waitDuration)
      }.`;

    switch (true) {
      case currentNumber === 0:
        setUserMessage(defaultMessage + " " + queueStartMessage);
        setButtonText("Wait for turn");
        break;

      case currentNumber === userCount - 1:
        setUserMessage("You are next in line. Please be ready.");
        setButtonText("Wait for turn");
        break;

      case currentNumber === userCount:
        setUserMessage("Please reach to the start of the queue.");
        setButtonText("Its your turn now");
        break;

      case waitDuration > 0:
        setUserMessage(defaultMessage + " " + waitingTimeMessage);
        setButtonText("Wait for turn");
        break;

      case currentNumber > userCount:
        setUserMessage("Your turn is completed");
        setButtonText("Turn is over");
        break;

      default:
        if (waitDuration === 0 && currentNumber === userCount) {
          setUserMessage(defaultMessage);
          setButtonText("It's your turn");
        } else {
          setUserMessage(defaultMessage + " " + waitingTimeMessage);
          setButtonText("Wait for turn");
        }
    }

    if (userMessage) setIsLoading(false);
  };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(id));
    setUserCount(userDetails && userDetails.count);
    setUserRegistrationDateTime(userDetails && userDetails.date);
  }, []);

  const userData =
    typeof window !== "undefined" ? JSON.parse(localStorage.getItem(id)) : null;

  const handleRemoveQueue = () => {
    setIsLoading(true);
    const data = {
      Device_Name: `${id}_App`,
      Date_Time: userRegistrationDateTime,
    };
    removeMeWaitlistQueue(data)
      .then((res) => {
        if (res.status === 200) {
          typeof window !== "undefined" && localStorage.removeItem(id);
          setIsLoading(false);
          const hashId = btoa(id);
          history.push(`/tr/${hashId}`);
        }
      })
      .catch((err) => console.log(err));
  };
  console.log("confirmTimeSlotconfirmTimeSlot", confirmTimeSlot);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(id));
    setUserCount(userDetails && userDetails.count);
    console.log("insideDAtaTIme");

    setUserRegistrationDateTime(userDetails && userDetails.date);
    setDeviceName(userDetails && userDetails.deviceName);
    // let deviceNameForWebSocket = `${deviceName}_App`
    // initWebSocket(deviceNameForWebSocket)
  
  }, []);
  useEffect(() => {
    // setDeviceName(userDetails && userDetails.deviceName);
    let deviceNameForWebSocket = `${deviceName}_App`;
    if (deviceName != "") {
      initWebSocket(deviceNameForWebSocket);
    }
  }, [deviceName]);

  function initWebSocket(deviceNameForWebSocket) {
    let wsUrl = "wss://urp7yi5tyj.execute-api.eu-central-1.amazonaws.com/test";
    // if (wsUrl) {
    ws = new WebSocket(wsUrl);
    initWebSocketsEvents(deviceNameForWebSocket);
    console.log("inside init");
    console.log("id id id id id id id id id ", deviceNameForWebSocket);
    // }
  }
  function initWebSocketsEvents(deviceNameForWebSocket) {
    ws.onopen = function (k) {
      // ws.send(JSON.stringify({action: 'default', k : k}));
      connected = true;
      sendFirst(deviceNameForWebSocket);
    };

    ws.onmessage = function (evt) {
      let message = evt.data;
      let parsedMessage = JSON.parse(message);
      console.log("getDelayMessage", parsedMessage);
      let audio = parsedMessage.audio;
      if (parsedMessage.Count) {
        let updatedCount = parsedMessage.Count;
        setCurrentNumber(updatedCount);
      } else {
        if (audio !== "no audio") {
          let updatedAudio = audio && audio.split("_");
          updatedAudio = updatedAudio[updatedAudio.length - 1];
          updatedAudio = updatedAudio.split(".")[0];
          updatedAudio = updatedAudio.match(
            /(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g
          )[1];
          updatedAudio = updatedAudio.replace("-", ":");
          updatedAudio = updatedAudio.replace("-", ":");
          let firstTime = localStorage.getItem(`${id}_dateTime`);
          if (firstTime > updatedAudio) {  
            setAudioMessage("");
            setAudioUrl("");
          }
        // }
        // let message = parsedMessage.message;
        // let closingTime = parsedMessage.closingTime;

        // setAudioMessage(message);
        // setHasAudio(true);
        // localStorage.setItem(`${id}_audio`, audio);
        // localStorage.setItem(`${id}_message`, message);
        // setAudioUrl(audio);
        // let delayTime = convertToSeconds(parsedMessage.delayTime);
        // const userDetails = JSON.parse(localStorage.getItem(id));
        // let date = userDetails.date;
        // let dateTime = date;
        // let dateLast = date.substring(19);
        // dateTime = dateTime.substring(0, 19);
        // dateTime = new Date(dateTime);

        // dateTime = moment(dateTime)
        //   .add(delayTime, "seconds")
        //   .format("YYYY-MM-DD/HH:mm:ss");
        // dateTime = dateTime + dateLast;
        // // localStorage.setItem(`${id}_audioset`, 1);
        // if (dateTime < closingTime) {
        //   if (localStorage.getItem(`${id}_audioset`) == "1") {
        //     console.log("insideDAtaTIme1");
        //   } else {
        //     console.log("insideDAtaTIme2");
        //     localStorage.setItem(`${id}_audioset`, 1);

        //     setUserRegistrationDateTime(dateTime);
        //     const userDetails = JSON.parse(localStorage.getItem(id));
        //     userDetails.date = dateTime;
        //     localStorage.setItem(id, JSON.stringify(userDetails));
        //     console.log("after after ", userRegistrationDateTime);
        //   }
        //   console.log("insideDAtaTIme3");
        // } else {
        //   setShowModal(true);
        }
      }
    };

    ws.onclose = function (k) {
      initWebSocket(deviceNameForWebSocket);
      connected = false;
    };

    ws.onerror = function (error) {
      console.error(error);
    };
  }
  function sendFirst(deviceNameForWebSocket) {
    // if (connected) {
    let message = deviceNameForWebSocket;
    // if (message) {
    ws.send(JSON.stringify({ action: "default", message }));
  }

  return (
    <div className={classes.root} style={{}}>
      {/* <div className={classes.logo}>
        <div className="goBack">
          <IconButton
            component="span"
            onClick={() => {
              history.goBack();
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
      </div> */}
      <HeaderMock />
      <span
        style={{
          display: "inline-flex",
          justifyContent: "center",
        }}
      >
        Your visiting slot for Queue of &nbsp; <span style={{ fontWeight: "bolder" }}>{queueName}</span>&nbsp;
      </span>
      <br></br>
      <span>
        on {moment(confirmTimeSlot, "YYYY-MM-DD/HH:mm:ss").format("DD MMM YYYY")}{" "}
      </span>
      {/* <span>{moment(confirmTimeSlot).format("YYYY/MM/DD")}</span> */}
      <span> at </span>
      {/* <span> {moment(String(confirmTimeSlot),'DD-MM-YYYY')}</span> */}

      <span>
        {moment(confirmTimeSlot, "YYYY-MM-DD/HH:mm:ss").format("hh:mm A")}
      </span>
      <br></br>
      <span> has been confirmed.</span>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: "20px", marginTop: "50px" }}>
          <Clock />
        </div>
        <CharacterIcon />
      </div>
      {/* <span>{confirmTimeSlot} </span> */}

      {/* <div className={classes.main}>
        <div className={classes.heading}>
          {!isLoading ? (
            <p>{userMessage}</p>
          ) : (
            <p className="waitingTimeLoader">
              Calculating estimated waiting time <CircularProgress />{" "}
            </p>
          )}
        </div>
        <div
          className={
            currentNumber === userCount
              ? classes.innerSecCurrent
              : classes.innerSec
          }
        >
          <span>{buttonText ? buttonText : "Wait for turn"}</span>
        </div>
        <div style={{ marginTop: "80px" }}>
          <div className={classes.innerSec}>
            <span>Name :</span>
            <p>{userData && userData.name}</p>
          </div>
          <div className={classes.innerSec}>
            <span>Your Number :</span>
            <p>{userCount}</p>
          </div>
          <div className={classes.innerSec}>
            <span>Current No :</span>
            <p style={{ display: "flex" }}>
              {!(currentNumber == null) ? (
                currentNumber
              ) : (
                <CircularProgress size="20px" />
              )}
            </p>
          </div>
          <div className={classes.removeWaitlistBtn}>
            <Button
              variant="contained"
              onClick={handleRemoveQueue}
              disabled={isLoading}
            >
              Remove me from the waitlist
            </Button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default RegistrationSuccess;
