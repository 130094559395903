import React from "react";
import Amplify from "aws-amplify";
import config from "../../aws-exports";
import { Authenticator } from "aws-amplify-react";
import useStyles from "../../assests/css/styles";
import AuthComponent from "./AuthComponent";
Amplify.configure(config);

const CAuthenticator = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.CAuthenticator}>
			<Authenticator hideDefault={true} amplifyConfig={config}>
				<AuthComponent />
				{props.children}
			</Authenticator>
		</div>
	);
};

export default CAuthenticator;
