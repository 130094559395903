import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import {
  getCurrentCount,
  getDelayMessage,
  removeMeWaitlistQueue,
} from "../api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../assests/css/style.css";
import { timeConverter } from "../services/utils";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { ReactComponent as CharacterIcon } from "../assests/images/characterIcon.svg";
import { HeaderMock } from "../component/HeaderMock";
import LoadingSpinner from "../component/auth-components/LoadingSpinner";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const useStyles = makeStyles((theme) => ({
  logo: {
    "& img": {
      width: 80,
    },
    "& .goBack": {
      // textAlign: "start",
      position: "absolute",
      left: "2px",
      "& span": {
        color: "#0386ee",
      },
    },
  },
  root: {
    textAlign: "-webkit-center",
    // width: "100%",
    // height: 500,
    // padding: 10,
    fontFamily: "'Manrope', sans-serif",
    height: "100vh",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
  main: {
    // boxShadow: "0px 0px 1px #262F56",
    borderRadius: 10,
    "& #qr-gen": {
      display: "none",
    },
    // [theme.breakpoints.down("sm")]: {
    //   width: "90%",
    // },
  },
  heading: {
    // backgroundColor: "#0386ee",
    height: "70px",
    borderRadius: "10px",
    display: "flex",
    color: "black",
    alignItems: "center",
    justifyContent: "center",
    width: 360,
    marginTop: "50px",
    [theme.breakpoints.up("md")]: {
      width: 405,
      padding: "0px 30px",
    },
    "& span": {
      margin: "13px 0px",
      padding: "10px",
      borderRadius: "10px",
      background: "#aaed07",
      fontWeight: 500,
      color: "#fff",
    },
    "& .waitingTimeLoader": {
      display: "flex",
      alignItems: "center",
      "& > span": {
        background: "none",
      },
    },
  },
  turnMsg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    // cursor: "pointer",
  },
  innerSec: {
    display: "flex",
    fontWeight: "bolder",
    margin: 25,
    height: "40px",
    justifyContent: "center",
    border: "1px solid #0386ee",
    width: "max-content",
    padding: "20px",
    alignItems: "center",
    borderRadius: "10px",
    marginRight: "8px",
    "& p": {
      margin: "0px 15px",
    },
  },
  innerSecCurrent: {
    display: "flex",
    margin: 25,
    height: "40px",
    background: "#04ee50",
    color: "#fff",
    justifyContent: "center",
    border: "1px solid #0386ee",
    width: "max-content",
    padding: "20px",
    alignItems: "center",
    borderRadius: "10px",
    "& p": {
      margin: "0px 15px",
    },
  },
  waitlistBtn: {
    margin: 20,
    marginTop: 80,
    "& Button": {
      backgroundColor: "#0386ee",
      color: "#fff",
      borderRadius: "10px",
      padding: "0px 30px",
      height: "50px",
    },
    "& Button:hover": {
      color: "#0386ee",
      border: "1px solid #0386ee",
      backgroundColor: "#fff",
    },
  },
  removeWaitlistBtn: {
    margin: 20,
    "& Button": {
      color: "white",
      background: "red",
      border: "1px solid red",
      height: "50px",
      borderRadius: "10px",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "#e74d4d",
      border: "1px solid #e74d4d",
      boxShadow: "none",
    },
  },
  audioPlayer: {
    "& .rhap_main-controls-button": {
      color: "white",
      marginTop: "-35px",
      marginLeft: "-208px",
    },
    "& .rhap_time": {
      color: "white",
    },
    "& .rhap_progress-section": {
      display: "flex",
      flex: "3 1 auto",
      alignItems: "center",
      marginLeft: "35px",
      marginTop: "10px",
    },
    "& .rhap_play-pause-button": {
      fontSize: "30px",
      width: "40px",
      height: "40px",
    },
    "& .rhap_progress-bar-show-download": {
      backgroundColor: "hsla(252,13%,46%,1)",
    },
    "& .rhap_progress-filled": {
      backgroundColor: "white",
    },
    "& .rhap_container": {
      border: "1px solid #0386ee",
      backgroundColor: "#060543",
      width: "253px !important",
    },
  },
  buttonCss: {
    color: "white",
    backgroundColor: "#0386ee",
    fontSize: "x-large",
    padding: "5px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  detailsFetchButton: {
    margin: 20,
    "& Button": {
      color: "white",
      background: "green",
      border: "1px solid green",
      height: "50px",
      borderRadius: "10px",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "green",
      border: "1px solid green",
      boxShadow: "none",
    },
    textBolder: {
      color: "black ",
      margin: "0",
      padding: "0",
      borderRadius: "0px",
      background: "white",
      fontWeight: "bolder",
    },
  },
}));

const TokenRegistration = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const count = location.state && location.state.count;
  let time = location.state && location.state.time;
  let yourName = location.state && location.state.yourName;
  let { id } = useParams();
  let urlId = useParams();
  id = atob(id);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAudio, setHasAudio] = useState(false);
  const _user = JSON.parse(localStorage.getItem(id));
  const [userRegistrationDateTime, setUserRegistrationDateTime] = useState(
    _user ? _user.date : ""
  );
  const [userCount, setUserCount] = useState(_user ? _user.count : "");
  const [estimatedWaitTime, setEstimatedWaitTime] = useState(0);
  const [waitDuration, setWaitDuration] = useState(0);
  const [queueDuration, setQueueDuration] = useState(5);
  const [queueOpeningTime, setQueueOpeningTime] = useState(null);
  const [userMessage, setUserMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [audioMessage, setAudioMessage] = useState("");
  const [isReCheck, setIsCheck] = useState(true);
  const [isConfirmButtonAvailable, setisConfirmButtonAvailable] =
    useState(true);
  const [isSlotSelectForTomorrow, setisSlotSelectForTomorrow] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isTurnGone, setisTurnGone] = useState(false);
  const [selayTime, setselayTime] = useState();
  const notify = () => {
    toast.info(audioMessage, { autoClose: true });
  };

  useEffect(() => {
    if (audioMessage) {
      notify();
    }
  }, [audioMessage]);

  let ws;
  let connected = false;

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(id));
    console.log("useEffectTokenIF1");
    console.log("useEffectTokenIF1", userDetails);

    if (!userDetails) {
      console.log("useEffectTokenIF1");
      history.push({
        pathname: `/tr/${urlId.id}`,
      });
    }
    if (userDetails && !userDetails.date) {
      console.log("useEffectTokenIF2");

      history.push({
        pathname: `/tr/${urlId.id}`,
      });
    }
    // if (userDetails.Your_Name === undefined || userDetails.Your_Name === null) {
    //   history.push({
    //     pathname: `/tr/${id}`,
    //   });
    //   console.log("history.push");
    // }
    // userDetails.Your_Name && console.log("userDetailsUseEffect", userDetails.Your_Name);
  }, []);

  useEffect(() => {
    getDelayMessage(id)
      .then((res) => {
        if (res.status === 200) {
          console.log("getDelayMessage", res.data.setTime);
          let audioCheck = localStorage.getItem(`${id}_audioset`);

          // console.log("moment().format('HH:mm:ss')", res.data.setTime);
          const userDetails = JSON.parse(localStorage.getItem(id));
          let date = userDetails.date;
          let dateTime = date;
          dateTime = dateTime.substring(0, 19);
          dateTime = new Date(dateTime);
          dateTime = moment(dateTime);
          console.log("getDelayMessage", dateTime.format("HH:mm:ss"));
          if (dateTime.format("HH:mm:ss") > res.data.setTime) {
            console.log("insideIFgetDelayMessage");
          } else if (dateTime.format("HH:mm:ss") < res.data.setTime) {
            console.log("insideelsegetDelayMessage");

            if (localStorage.getItem(`${id}_audioset`) !== "1") {
              let audio = res.data.link;
              if (audio !== "no audio") {
                let updatedAudio = audio.split("_");
                updatedAudio =
                  updatedAudio && updatedAudio[updatedAudio.length - 1];
                updatedAudio = updatedAudio.split(".")[0];
                updatedAudio = updatedAudio.match(
                  /(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g
                )[1];
                updatedAudio = updatedAudio.replace("-", ":");
                updatedAudio = updatedAudio.replace("-", ":");
                let firstTime = localStorage.getItem(`${id}_dateTime`);
                if (firstTime > updatedAudio) {
                  setAudioMessage("");
                  setAudioUrl("");
                }
              }
              let message = res.data.message;
              setAudioMessage(message);
              setHasAudio(true);
              localStorage.setItem(`${id}_audio`, audio);
              localStorage.setItem(`${id}_message`, message);
              setAudioUrl(audio);
              let delayTime = convertToSeconds(res.data.delayTime);
              const userDetails = JSON.parse(localStorage.getItem(id));
              let date = userDetails.date;
              let dateTime = date;
              let dateLast = date.substring(19);
              dateTime = dateTime.substring(0, 19);
              dateTime = new Date(dateTime);
              dateTime = moment(dateTime)
                .add(delayTime, "seconds")
                .format("YYYY-MM-DD/HH:mm:ss");
              dateTime = dateTime + dateLast;
              localStorage.setItem(`${id}_audioset`, 1);

              if (dateTime < res.data.closingTime) {
                console.log("insideDAtaTIme");
                setUserRegistrationDateTime(dateTime);
                const userDetails = JSON.parse(localStorage.getItem(id));
                userDetails.date = dateTime;
                localStorage.setItem(id, JSON.stringify(userDetails));
              } else {
                setShowModal(true);
              }
            }
          }
        } else {
          console.log(" already set ");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    if (currentNumber) {
      calculateWaitDuration();
    }
    getUserMessage();
  }, [queueOpeningTime, waitDuration, currentNumber]);

  useEffect(() => {
    // const todayDate = moment(new Date()).format("DD/MM/YYYY");
    // console.log(todayDate);
    // console.log(
    //   "booked slot time is==>>>> ",

    // );

    // if (isReCheck) {
    //   const interval = setInterval(() => {
    setIsLoading(true);
    let delayAudio = localStorage.getItem(`${id}_audio`);
    let delayMessage = localStorage.getItem(`${id}_message`);
    if (delayAudio !== null) {
      setAudioUrl(delayAudio);
      setAudioMessage(delayMessage);
      setHasAudio(true);
    }

    if (time === undefined) {
      if (localStorage.getItem(id)) {
        const _user = localStorage.getItem(id);
        if (_user) {
          const user = JSON.parse(_user);
          const assignDate = user.date;
          time = assignDate;
          fetchCurrentCount();
        }
      } else {
        const hashId = btoa(id);
        history.push(`/tr/${hashId}`);
      }
    }
    fetchCurrentCount();
    // calculateWaitDuration();
    // getUserMessage();
    // }, 1000);
    // return () => {
    //   clearInterval(interval);
    // };
    // }
  }, []);
  useEffect(() => {
    calculateWaitDuration();
    getUserMessage();
  }, [currentNumber, waitDuration]);

  const fetchCurrentCount = () => {
    setIsCheck(false);
    getCurrentCount(id, userRegistrationDateTime)
      .then((res) => {
        console.log("fetchCountRes", res);
        setIsCheck(true);
        setCurrentNumber(res.currentNum);
        setEstimatedWaitTime(res.appointmentTime);
        setQueueDuration(res.duration);
        setUserCount(res.yourNumber);
        setQueueOpeningTime(res.openingTime);
        if (isNaN(res.yourNumber)) {
          localStorage.clear();

          history.push({
            pathname: `/tr/${urlId.id}`,
          });
        }
        // calculateWaitDuration();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    calculateWaitDuration();
  }, [userCount]);
  // useEffect(() => {
  //   if (currentNumber) {
  //     calculateWaitDuration();
  //   }
  //   getUserMessage();
  // }, [isTurnGone]);

  const calculateWaitDuration = () => {
    if (userCount == currentNumber) {
      setWaitDuration(0);
    } else {
      let waitingDate = moment(estimatedWaitTime, "hh:mm a");
      let waitMinutes = waitingDate.diff(moment(), "minutes");
      let minimumWaitTime = (userCount - (currentNumber + 1)) * queueDuration;
      setWaitDuration(
        waitMinutes < minimumWaitTime ? minimumWaitTime : waitMinutes
      );
    }
    // setIsLoading(false)
  };
  // useEffect(() => {}, [isSlotSelectForTomorrow]);

  // const isSlotBookedForTommorowHandler = () => {
  // if (
  //   moment(time).format("DD-MM-YYYY") !=
  //   moment(new Date()).format("DD/MM/YYYY")
  // ) {
  //   console.log("not equal dat ");
  //   setisSlotSelectForTomorrow(false);
  // }
  // };
  const convertToSeconds = (b) => {
    var a = b.split(":");
    var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    return seconds;
  };
  const getUserMessage = () => {
    // isSlotBookedForTommorowHandler();
    // const defaultMessage = `You are enrolled in line at ${
    // const defaultMessage = `Hi   <span style={{color:"red"}}>{yourName}  </span>  Your Number is ${userCount} in Queue  ${
    //   id && id.split("_")[2]
    // }.
    // Your Slot is selected for ${moment(time).format("DD-MM-YYYY")} at ${moment(
    //   time
    // ).format("hh:mm a")}. Current Number is ${currentNumber}. Please wait for ${
    //   isNaN(waitDuration) || waitDuration <= 0 ? 0 : timeConverter(waitDuration)
    // }.`;
    const defaultMessage = "";

    const queueStartMessage = `You are at position: ${userCount}. ${
      queueOpeningTime ? `Queue will start at ${queueOpeningTime}` : ""
    }`;
    // const waitingTimeMessage = `Your estimated waiting time is ${isNaN(waitDuration) || waitDuration <= 0 ? 0 : timeConverter(waitDuration)
    //   }.`;
    switch (true) {
      case currentNumber === 0:
        setUserMessage(defaultMessage);
        setButtonText("Wait for turn");
        setisConfirmButtonAvailable(true);
        setIsLoading(false);
        break;

      case currentNumber === userCount - 1:
        // setUserMessage(". Please be ready.");
        setButtonText("Wait for turn");
        setIsLoading(false);
        setisConfirmButtonAvailable(true);
        setIsLoading(false);
        break;

      case currentNumber === userCount:
        setisConfirmButtonAvailable(false);
        // setUserMessage("Please reach to the start of the queue.");
        setButtonText("Its your turn now");
        setIsLoading(false);
        break;

      case waitDuration > 0:
        // setUserMessage(defaultMessage + " " + waitingTimeMessage);
        setButtonText("Wait for turn");
        setIsLoading(false);
        setisConfirmButtonAvailable(true);
        setIsLoading(false);
        break;
      case currentNumber > userCount + 2:
        localStorage.removeItem(id);
        localStorage.removeItem("isSlotSelectedForTomorrow");
        localStorage.removeItem(`${id}_audio`);
        localStorage.removeItem(`${id}_message`);
        setIsLoading(false);
        const hashId = btoa(id);
        history.push(`/tr/${hashId}`);
        setIsLoading(false);
        break;

      case currentNumber > userCount:
        setisConfirmButtonAvailable(false);
        setisTurnGone(true);
        setUserMessage("Your turn is completed Thank you for visiting Us");
        setButtonText("Turn is over");
        setIsLoading(false);
        // localStorage.removeItem(id);
        // setIsLoading(false);
        // console.log("currentNumber > userCount");
        // localStorage.removeItem(id);
        // setIsLoading(false);
        // const hashId = btoa(id);
        // history.push(`/tr/${hashId}`);
        break;

      default:
        setisTurnGone(false);
        if (waitDuration == 0 && currentNumber == userCount) {
          setUserMessage(defaultMessage);
          setButtonText("It's your turn");
          setisConfirmButtonAvailable(false);
          // setisTurnGone(false);
        } else {
          // setUserMessage(defaultMessage + " " + waitingTimeMessage);
          setButtonText("Wait for turn");
          // setisTurnGone(false);
          setisConfirmButtonAvailable(true);
        }
    }
    if (userMessage) setIsLoading(false);
  };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(id));
    setUserCount(userDetails && userDetails.count);
    console.log("insideDAtaTIme");

    setUserRegistrationDateTime(userDetails && userDetails.date);
    setDeviceName(userDetails && userDetails.deviceName);
    // let deviceNameForWebSocket = `${deviceName}_App`
    // initWebSocket(deviceNameForWebSocket)
    if (
      moment(userRegistrationDateTime, "YYYY-MM-DD/HH:mm:ss").format(
        "DD/MM/YYYY"
      ) != moment(new Date()).format("DD/MM/YYYY")
    ) {
      setisSlotSelectForTomorrow(true);
    }
  }, []);

  useEffect(() => {
    // setDeviceName(userDetails && userDetails.deviceName);
    let deviceNameForWebSocket = `${deviceName}_App`;
    if (deviceName != "") {
      initWebSocket(deviceNameForWebSocket);
    }
  }, [deviceName]);

  // const userData =
  //   typeof window !== "undefined" ? JSON.parse(localStorage.getItem(id)) : null;

  const handleRemoveQueue = () => {
    setIsLoading(true);
    setIsLoadingSpinner(true);

    let removeuserRegistrationDateTime = userRegistrationDateTime;
    if (localStorage.getItem(`${id}_delayTime`)) {
      // const delayTimeSecond = localStorage.getItem(`${id}_delayTime`);
      // removeuserRegistrationDateTime = moment(removeuserRegistrationDateTime)
      //   .add(delayTimeSecond, "seconds")
      //   .format("YYYY-MM-DD/HH:mm:ss");
      // console.log(
      //   "removeuserRegistrationDateTime",
      //   moment(removeuserRegistrationDateTime)
      //     .subtract(delayTimeSecond, "seconds")
      //     .format("YYYY-MM-DD/HH:mm:ss")
      // );
      console.log(
        "removeuserdataRegistrationDateTime",
        userRegistrationDateTime
      );
      // const data = {
      //   Device_Name: `${id}_App`,
      //   Date_Time: moment(userRegistrationDateTime)
      //     .add(localStorage.getItem(`${id}_delayTime`), "seconds")
      //     .format("YYYY-MM-DD/HH:mm:ss"),
      // };
      const data = {
        Device_Name: `${id}_App`,
        Date_Time: userRegistrationDateTime,
      };
      console.log("removeuserRegistrationDateTimeIf", data);
      removeMeWaitlistQueue(data)
        .then((res) => {
          if (res.status === 200) {
            typeof window !== "undefined" && localStorage.removeItem(id);
            localStorage.removeItem("isSlotSelectedForTomorrow");
            localStorage.removeItem(`${id}_audio`);
            localStorage.removeItem(`${id}_message`);
            localStorage.removeItem(`${id}_audioset`);
            localStorage.removeItem(`${id}_delayTime`);
            localStorage.clear();
            setIsLoading(false);
            const hashId = btoa(id);
            history.push(`/tr/${hashId}`);
            setIsLoadingSpinner(false);
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log(
        "removeuserRegistrationDateTimeelse",
        userRegistrationDateTime
      );

      const data = {
        Device_Name: `${id}_App`,
        Date_Time: userRegistrationDateTime,
      };
      console.log("removeuserRegistrationDateTime", data);
      removeMeWaitlistQueue(data)
        .then((res) => {
          if (res.status === 200) {
            typeof window !== "undefined" && localStorage.removeItem(id);
            localStorage.removeItem("isSlotSelectedForTomorrow");
            localStorage.removeItem(`${id}_audio`);
            localStorage.removeItem(`${id}_message`);
            localStorage.removeItem(`${id}_audioset`);

            setIsLoading(false);
            const hashId = btoa(id);
            history.push(`/tr/${hashId}`);
            setIsLoadingSpinner(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  function initWebSocket(deviceNameForWebSocket) {
    let wsUrl = "wss://urp7yi5tyj.execute-api.eu-central-1.amazonaws.com/test";
    // if (wsUrl) {
    ws = new WebSocket(wsUrl);
    initWebSocketsEvents(deviceNameForWebSocket);
    console.log("inside init");
    console.log("id id id id id id id id id ", deviceNameForWebSocket);
    // }
  }

  function initWebSocketsEvents(deviceNameForWebSocket) {
    ws.onopen = function (k) {
      // ws.send(JSON.stringify({action: 'default', k : k}));
      connected = true;
      sendFirst(deviceNameForWebSocket);
    };

    ws.onmessage = function (evt) {
      let message = evt.data;
      let parsedMessage = JSON.parse(message);
      console.log("getDelayMessage", parsedMessage);
      let audio = parsedMessage.audio;
      if (parsedMessage.Count) {
        let updatedCount = parsedMessage.Count;
        setCurrentNumber(updatedCount);
      } else {
        if (audio && audio !== "no audio") {
          let updatedAudio = audio && audio.split("_");
          updatedAudio = updatedAudio[updatedAudio.length - 1];
          updatedAudio = updatedAudio.split(".")[0];
          updatedAudio = updatedAudio.match(
            /(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g
          )[1];
          updatedAudio = updatedAudio.replace("-", ":");
          updatedAudio = updatedAudio.replace("-", ":");
          let firstTime = localStorage.getItem(`${id}_dateTime`);
          if (firstTime > updatedAudio) {
            setAudioMessage("");
            setAudioUrl("");
          }
        }
        let message = parsedMessage.message;
        let closingTime = parsedMessage.closingTime;

        setAudioMessage(message);
        setHasAudio(true);
        localStorage.setItem(`${id}_audio`, audio);
        localStorage.setItem(`${id}_message`, message);
        setAudioUrl(audio);
        let delayTime = convertToSeconds(parsedMessage.delayTime);
        const userDetails = JSON.parse(localStorage.getItem(id));

        if (userDetails.date) {
          let date = userDetails.date;
          let dateTime = date;
          let dateLast = date.substring(19);
          dateTime = dateTime.substring(0, 19);
          dateTime = new Date(dateTime);

          dateTime = moment(dateTime)
            .add(delayTime, "seconds")
            .format("YYYY-MM-DD/HH:mm:ss");
          dateTime = dateTime + dateLast;
          // localStorage.setItem(`${id}_audioset`, 1);
          if (dateTime < closingTime) {
            if (localStorage.getItem(`${id}_audioset`) == "1") {
              console.log("insideDAtaTIme1");
            } else {
              console.log("insideDAtaTIme2");
              localStorage.setItem(`${id}_audioset`, 1);

              setUserRegistrationDateTime(dateTime);
              const userDetails = JSON.parse(localStorage.getItem(id));
              userDetails.date = dateTime;
              localStorage.setItem(id, JSON.stringify(userDetails));
              console.log("after after ", userRegistrationDateTime);
            }
            console.log("insideDAtaTIme3");
          } else {
            setShowModal(true);
          }
        } else {
          history.push({
            pathname: `/tr/${urlId.id}`,
          });
        }
      }
    };

    ws.onclose = function (k) {
      initWebSocket(deviceNameForWebSocket);
      connected = false;
    };

    ws.onerror = function (error) {
      console.error(error);
    };
  }

  const changeUrl = () => {
    // handleRemoveQueue();
    typeof window !== "undefined" && localStorage.removeItem(id);
    console.log("changeURl");
    localStorage.removeItem("isSlotSelectedForTomorrow");
    localStorage.removeItem(`${id}_audio`);
    localStorage.removeItem(`${id}_message`);
    setIsLoading(false);
    const hashId = btoa(id);
    history.push(`/tr/${hashId}`);
    setIsLoadingSpinner(false);
  };

  function sendFirst(deviceNameForWebSocket) {
    // if (connected) {
    let message = deviceNameForWebSocket;
    // if (message) {
    ws.send(JSON.stringify({ action: "default", message }));
  }
  const TEST = (parameter) => {
    return (
      <>
        {/* Hello{" "}
        <span
          style={{
            color: "black ",
            margin: "0",
            padding: "0",
            borderRadius: "0px",
            background: "white",
            fontWeight: "bolder",
          }}
        >
          {parameter.yourName}{" "}
        </span> */}
        {/* Queue */}
        {/* {" "} */}
        <table>
          <tr className="tableData1">
            <td textAlign="left">Queue Name :</td>
            {/* <span
          style={{
            color: "black ",
            margin: "0",
            padding: "0",
            borderRadius: "0px",
            background: "white",
            fontWeight: "bolder",
          }}
        > */}
            <td style={{ fontWeight: "bold" }}>
              {parameter.id && parameter.id.split("_")[2]}
            </td>
            {/* </span> */}
          </tr>
          <tr className="tableData2">
            <td textAlign="left"> Your Number : </td>
            {/* {" "} */}
            {/* <span
          style={{
            color: "black ",
            margin: "0",
            padding: "0",
            borderRadius: "0px",
            background: "white",
            fontWeight: "bolder",
          }}
        > */}
            <td style={{ fontWeight: "bold" }}>{parameter.userCount}</td>
            {/* </span>{" "} */}
          </tr>
          <tr className="tableData3">
            <td textAlign="left">Current Number :</td>
            {/* {" "}
        <span
          style={{
            color: "black ",
            margin: "0",
            padding: "0",
            borderRadius: "0px",
            background: "white",
            fontWeight: "bolder",
          }}
        > */}
            <td style={{ fontWeight: "bold" }}>{parameter.currentNumber}</td>
            {/* </span> */}
          </tr>
          <tr className="tableData4">
            <td textAlign="left">Appointment : </td>
            {/* {" "}
       {" "} */}
            {/* at */}
            {/* {" "}
        <span
          style={{
            color: "black ",
            margin: "0",
            padding: "0",
            borderRadius: "0px",
            background: "white",
            fontWeight: "bolder",
          }}
        >{" "}
           <span
          style={{
            color: "black ",
            margin: "0",  
            padding: "0",
            borderRadius: "0px",
            background: "white",
            fontWeight: "bolder",
          }}
        >
        </span> */}
            <td style={{ fontWeight: "bold" }}>
              {moment(
                parameter.userRegistrationDateTime,
                "YYYY-MM-DD/HH:mm:ss"
              ).format("hh:mm A")}
              ,{" "}
              {moment(
                parameter.userRegistrationDateTime,
                "YYYY-MM-DD/HH:mm:ss"
                // ).format("MMM d, y")}
              ).format("DD MMM")}
            </td>
            {/* </span> {" "} */}
          </tr>

          {/* Please wait for{" "}
        <span
          style={{
            color: "black ",
            margin: "0",
            padding: "0",
            borderRadius: "0px",
            background: "white",
            fontWeight: "bolder",
          }}
        >
          {isNaN(parameter.waitDuration) || parameter.waitDuration <= 0
            ? 0
            : timeConverter(parameter.waitDuration)}
        </span>{" "}
        .
         */}
        </table>
      </>
    );
  };
  console.log("isTurnGone==", isTurnGone);
  const NextDatSlotSelection = () => (
    <div>
      <p style={{ marginTop: "25vh", alignContent: "center" }}>
        Queue is closed for Today your slot is selected for tomorrow{" "}
      </p>

      <p style={{ alignContent: "center" }}> Do you want to continue</p>
      <button
        className={classes.buttonCss}
        onClick={() => {
          handleRemoveQueue();
        }}
        style={{
          backgroundColor: "red",
          margin: "13px",
          color: "white",
          borderRadius: "4px",
          fontSize: "large",
        }}
      >
        No
      </button>
      <button
        style={{
          marginLeft: "10px",
          margin: "13px",
          color: "white",
          borderRadius: "4px",
          fontSize: "large",
          backgroundColor: "#060543",
        }}
        className={classes.buttonCss}
        onClick={() => {
          localStorage.setItem("isSlotSelectedForTomorrow", true);
          setisSlotSelectForTomorrow(false);
        }}
      >
        Yes
      </button>
    </div>
  );
  useEffect(() => {
    const isSlotSelectedForTomorrowSelected = localStorage.getItem(
      "isSlotSelectedForTomorrow"
    );
    if (isSlotSelectedForTomorrowSelected) {
      setisSlotSelectForTomorrow(false);
    }
    // localStorage.setItem("isSlotSelectedForTomorrow", true);
  }, []);
  if (showModal) {
    localStorage.removeItem(id);
  }

  return (
    <div className={classes.root} style={{}}>
      <HeaderMock />

      {/* <div className={classes.logo}> */}
      {/* <div className="goBack">
          <IconButton
            component="span"
            onClick={() => {
              // history.goBack();
              // history.go(1);
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div> */}
      {/* <img src={LogoIcon} alt="logo" /> */}
      {/* <ReactSVG src={characterIcon} className="character" /> */}
      {/* <MQTTConnection
          setCurrentNumber={setCurrentNumber}
          currentCountTopic={`${id}_Device`}
        /> */}
      {/* </div> */}

      {isSlotSelectForTomorrow ? (
        <NextDatSlotSelection />
      ) : !showModal ? (
        <div className={classes.main}>
          <div className={classes.heading}>
            {console.log("currentNumber", currentNumber)}
            {!isLoading && !(currentNumber == null) ? (
              isTurnGone ? (
                <p>{userMessage}</p>
              ) : (
                <p>
                  {TEST({
                    yourName,
                    userCount,
                    id,
                    userRegistrationDateTime,
                    currentNumber,
                    waitDuration,
                  })}
                  {userMessage}
                  {isSlotSelectForTomorrow && (
                    <p>your slot is selected for tomorrow</p>
                  )}
                </p>
              )
            ) : (
              <p className="waitingTimeLoader">
                Calculating estimated waiting time <CircularProgress />{" "}
              </p>
            )}
          </div>
          {/* {isSlotSelectForTomorrow ? (
          <div>
            <p>Your slot is selected for {moment(time).format("DD-MM-YYYY")}</p>
            <p>At {moment(time).format("hh:mm a")}</p>
          </div>
        ) : null} */}

          <div className={classes.turnMsg}>
            <div
              className={
                !isConfirmButtonAvailable
                  ? classes.innerSecCurrent
                  : classes.innerSec
              }
              // onClick={() => {
              //   fetchCurrentCount();
              // }}
            >
              <span>{buttonText ? buttonText : "Wait for turn"}</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <CachedIcon
                sx={{ cursor: "pointer", color: "#0386ee" }}
                onClick={() => {
                  fetchCurrentCount();
                }}
              />
            </div>
          </div>
          <div>
            {/* <div className={classes.innerSec}>
            <span>Name :</span>
            <p>{userData && userData.name}</p>
          </div> */}
            {/* <div className={classes.innerSec}>
            <span>Your Number :</span>
            {isSlotSelectForTomorrow ? (
              <p>/NA/</p>
            ) : (
              <p>{isNaN(userCount) ? "" : userCount}</p>
            )}
          </div> */}
            {/* <div className={classes.innerSec}>
            <span>Current No :</span>

            {isSlotSelectForTomorrow ? (
              <p>/NA/</p>
            ) : (
              <p style={{ display: "flex" }}>
                {!(currentNumber == null) ? (
                  currentNumber
                ) : (
                  <CircularProgress size="20px" />
                )}
              </p>
            )}
          </div> */}
            {/* <div className={classes.waitlistBtn}>
            <Button variant="outlined">View waitlist</Button>
          </div> */}

            <CharacterIcon />
            {audioUrl !== "no audio" ? (
              <div className={classes.audioPlayer}>
                <AudioPlayer
                  // style={{ width: "300px" }}
                  style={
                    hasAudio
                      ? { borderRadius: "1rem", width: "300px" }
                      : { display: "none" }
                  }
                  autoPlay={false}
                  autoPlayAfterSrcChange={false}
                  // layout="horizontal"
                  src={audioUrl}
                  onPlay={(e) => console.log("onPlay")}
                  // showSkipControls={true}
                  showJumpControls={false}
                  customAdditionalControls={[]}
                  customVolumeControls={[]}
                  preload="metadata"
                  timeFormat="mm:ss"
                  // header={`Now playing: ${musicTracks[trackIndex].name}`}
                  // footer="All music from: www.bensound.com"
                  // onClickPrevious={handleClickPrevious}
                  // onClickNext={handleClickNext}
                  // onEnded={handleClickNext}
                />
              </div>
            ) : null}
            {isConfirmButtonAvailable ? (
              <div className={classes.removeWaitlistBtn}>
                <Button
                  variant="contained"
                  onClick={handleRemoveQueue}
                  disabled={isLoading}
                >
                  Remove me from the waitlist
                  {isLoadingSpinner ? (
                    <LoadingSpinner style={{ marginLeft: "45px" }} />
                  ) : null}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "230px" }}>
          <div style={{ fontWeight: "bold" }}>
            Your slot has been cancelled.
            <div>
              <div>you can book slot for tommorrow.</div>
              <Button
                variant="contained"
                onClick={changeUrl}
                style={{
                  color: "white",
                  backgroundColor: "midnightblue",
                  marginTop: "10px",
                }}
              >
                Register
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TokenRegistration;
