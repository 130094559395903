import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // [theme.breakpoints.down("md")]: {
  //   InputNone: {
  //     position: "absolute",
  //     // inset: "0px",
  //     margin: "0px",
  //     right: "0px",
  //     top: "70px",
  //     // transform: "translate3d(0px)",
  //   },
  // },

  audioPlayer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    "& .rhap_main-controls-button": {
      color: "white",
      marginTop: "-35px",
      marginLeft: "-208px",
    },
    "& .rhap_time": {
      color: "white",
      // display:"none"
    },
    "& .rhap_progress-section": {
      display: "flex",
      flex: "3 1 auto",
      alignItems: "center",
      marginLeft: "35px",
      marginTop: "10px",
    },
    "& .rhap_play-pause-button": {
      fontSize: "30px",
      width: "40px",
      height: "40px",
    },
    "& .rhap_progress-bar-show-download": {
      backgroundColor: "hsla(252,13%,46%,1)",
    },
    "& .rhap_progress-filled": {
      backgroundColor: "white",
    },
    "& .rhap_container": {
      border: "1px solid #0386ee",
      backgroundColor: "#060543",
      width: "253px !important",
    },
    "& .rhap_totalTime": {
      display: "none",
    },
  },
  customInput: {
    borderRadius: "15px",
    border: "1px #0c8af8 solid",
    padding: "4px 12px",
    backgroundColor: "white",
    height: "22px",
    boxShadow: "0 0 2px #0074d9",
  },
  audioRecorder: {
    display: "inline-block",
    marginTop: "20px",
    marginBottom: "20px",
  },
  voiceRecorder: {
    textAlign: "center",
    backgroundColor: "white",

    // height: '95vh',
    // marginTop:'100px',
    "& ._1Yplu ._37kfa ": {
      display: "none",
    },
    "& ._1ceqH ._3O0Io": {
      borderColor: " transparent transparent transparent black",
    },
    "& ._1ceqH ._1Yplu ._37kfa": {
      background: "#060543",
      color: "#fff",
      cursor: "pointer",
      border: "1px solid #060543",
    },
    "& ._1ceqH ._1Yplu ._2gd2_": {
      background: "#d32f2f",
      display: "none",
      color: "#fff",
      cursor: "pointer",
      border: "1px solid #d32f2f",
      marginLeft: "-10px",
    },
    "& ._1ceqH ._2ACrw": {
      width: "39px",
      height: "25px",
      borderStyle: "double",
      borderWidth: "0px 0px 0px 18px",
      borderColor: "black",
    },
    "& ._1ceqH ._oEOY-": {
      width: "26px",
      height: "25px",
      border: " 3.5px solid black",
      borderRadius: "2px",
    },
    "& ._1ceqH ._1Yplu": {
      marginTop: "430px",
      marginLeft: "170px",
    },
    "& ._1ceqH ._f2DT8 *": {
      color: "black",
    },
    "& ._1ceqH ._1YOWG ._eV_dK": {
      color: "black",
    },
    "& ._1lB9c": {
      display: "none",
    },
    "& ._1ceqH ._1dpop:hover ._3wi1g": {
      fill: "black",
    },
    "& ._1ceqH ._qxztz": {
      bottom: "160px",
    },
    "& ._1ceqH ._3bC73 ._1YOWG": {
      top: "52px",
    },
    "& ._1ceqH ._3bC73 ._1dpop": {
      bottom: "144px",
    },
    "& ._1ceqH ._dt3-T": {
      backgroundColor: "white",
    },
    "& ._1ceqH ._1dpop": {
      backgroundColor: "white",
    },
    "& ._1ceqH ._qxztz ._1bSom": {
      backgroundColor: "white",
      color: "black",
    },
    "& ._1ceqH ._qxztz ._3nQu5": {
      backgroundColor: "white",
      color: "black",
    },
    "& ._1YOWG": {
      color: "black",
    },
    "& ._3wi1g": {
      height: "34px",
      fill: "black",
    },
  },
  listSec: {
    cursor: "pointer",
    display: "flex",
    // padding: 4,
    textAlign: "left",
    padding: "10px 15px",
    marginTop: "10px",
    backgroundColor: "gray",
    "&:hover": {
      backgroundColor: "lightGray",
    },
    "& button": {
      position: "absolute",
      "& svg": {
        marginTop: "-12px",
      },
    },
    "& li": {
      width: "100%",
    },
  },
  menuList: {
    fontSize: "14px",
    backgroundColor: "#fff",
    borderRadius: "2px",
    padding: "15px",
    textAlign: "center",
    minWidth: "120px",
    height: "240px",
    overflowY: "auto",
    overflowX: "hidden",
    margin: "0",
    position: "absolute",
    listStyle: "none",
    boxShadow: "0 0 20px 0 #ccc",
    opacity: 1,
    transition: "opacity 0.5s linear",
    top: "100%",
    zIndex: 9,
    "& .MuiFormControl-root": {
      maxWidth: "100% !important",
    },
    "& .MuiInputBase-root": {
      "& input": {
        border: "1px solid",
      },
    },
  },
  moreVertIcon: {
    "& .MuiPopover-paper": {
      position: "relative",
    },
  },
  checkBox: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  root: {
    display: "flex",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#FFF",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    flexShrink: 0,
    width: 200,
  },
  drawerPaper: {
    width: 200,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    borderRadius: "0%",
    "&:hover": {
      background: "transparent",
    },
    // [theme.breakpoints.up("md")]: {
    //   display: "none"
    // }
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  calendraPaper: {
    // position: "absolute !important",
    // inset: "unset !important",
    top: "180px !important",
    left: "90% !important",
    transform: "translate(-370%, 0) !important",
    // margin: "0px !important",
    "@media screen and (max-width: 1080px)": {
      //   position: "absolute !important",
      //   inset: "unset !important",
      //   top: "70px !important",
      //   right: "0px !important",
      //   margin: "0px !important",
    },
    "@media screen and (max-width: 1280px)": {
      transform: "translate(-90%, 0) !important",
      //   position: "absolute !important",
      //   inset: "unset !important",
      //   top: "70px !important",
      //   right: "0px !important",
      //   margin: "0px !important",
    },
  },
  positionMobile: {
    // color: "white !important",
    display: " inline-flex",
    border: "1px solid #00000073",
    borderRadius: "2px",
    padding: "4px",
    marginLeft: "40px",
    // "& svg": {
    //   fill: "white",
    // },
    //   "& .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root": {
    //     position: "absolute",
    //     inset: "auto",
    //     top: "70px",
    //     right: "0px",
    //     margin: "0px",
    //   },
  },

  InputNone: {
    // "& Input": {
    //   display: "none !important",
    // },
    [theme.breakpoints.down("md")]: {
      "& Input": {
        // display: "none !important",
        // borderRadius: '50%',
        display: "inline-block",
      },
    },
  },

  /* .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
    position: absolute !important;
    inset :  auto !important;
    top: 70px !important;
    margin: 0px !important;
  }
  
  @media screen and (max-width: 1080px) {
    .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
        position: absolute !important;
        inset :  auto !important;
        top: 70px !important;
        right: 0px !important;  
        margin: 0px !important;
      }
  }
  @media screen and (max-width: 1280px) {
    .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
        position: absolute !important;
        inset :  auto !important;
        top: 70px !important;
        right: 0px !important;  
        margin: 0px !important;
      }
  } */

  calenderSection: {
    // boxShadow: '0px 0px 1px #262F56',
    borderRadius: 10,
    // width: "70%",
    padding: 25,
    // textAlign: "initial",
    // boxShadow: "none",
    // fontFamily: "'Manrope', 'sans-serif'",
  },
  mainSection: {
    "& .MuiBottomNavigation-root": {
      background: "#060543",
      height: 65,
      "& button": {
        color: "#fff",
        "& span": {
          fontSize: 15,
        },
      },
    },
    "& .Mui-selected": {
      background: "#828e9a",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      width: "30%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
  root: {
    textAlign: "-webkit-center",
    // marginTop: 100,
  },
  rootLogo: {
    textAlign: "center",
    position: "sticky",
    top: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#060543",
    zIndex: 1000,
    // marginTop: 10,
    "& img": {
      width: 128,
      paddingTop: 20,
      paddingBottom: 20,
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& span": {
      "& svg": {
        // width: "2em",
        height: "2em",
        color: "white",
      },
      position: "absolute",
      top: "50%",
      left: 20,
      transform: "translateY(-50%) translateX(-50%)",
    },
  },
  deviceButton: {
    marginTop: 21,
    marginBottom: 30,
    width: "51%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textField: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  hideButton: {
    display: "none",
  },
  main: {
    "& .MuiListItem-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f6f6f6f6",
      },
    },
    "& .MuiListItemText-primary": {
      color: "#060543",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        textAlign: "start",
      },
    },
    "& .MuiCircularProgress-colorPrimary": {
      position: "absolute",
      top: "50%",
      left: "50%",
      color: "#0486ee",
    },
  },
  addBtn: {
    "& button": {
      bottom: 70,
      right: 20,
      position: "absolute",
    },
    "& .MuiFab-primary": {
      backgroundColor: "#0586ee",
      position: "fixed",
      boxShadow: "none",
    },
  },
  removeBtn: {
    "& button": {
      bottom: 70,
      right: 100,
      position: "absolute",
    },
    "& .MuiFab-primary": {
      backgroundColor: "#0586ee",
      position: "fixed",
    },
  },
  bottomNavBar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    "& svg": {
      fill: "#fff",
    },
    // fontFamily: "Manrope",
  },
  noQueue: {
    position: "relative",
    top: "18vh",
    // left:"45%",
    textAlign: "center",
    "& p": {
      marginTop: "40px",
      fontSize: 25,
      color: "red",
    },
    "& .MuiButton-text": {
      padding: "8px 40px",
    },
  },
  noDevice: {
    position: "relative",
    top: "24vh",
    textAlign: "center",
    "& p": {
      fontSize: 20,
      // fontWeight:100
    },
  },
  queueLoader: {
    position: "relative",
    top: "26vh",
  },
  loader: {
    position: "relative",
    top: "34vh",
    left: "100vh",
  },
  greeting: {
    paddingTop: 20,
    "& p": {
      lineHeight: 1,
      textAlign: "left",
      paddingLeft: 30,
      fontSize: 16,
      fontFamily: "'Manrope', sans-serif",
    },
  },
  inputError: {
    // border: "1px solid #f44336 !important",
  },
  deviceIcon: {
    width: "210px",
    height: "176px",
    // marginBottom: "108px",
  },
  addQueue: {
    alignItems: "center",
    position: "relative",
    // top: "18%",
    marginTop: "50px !important",
    padding: "0px 35px",
    "& .MuiGrid-item": {
      textAlign: "start",
      width: "100%",
      fontsize: "14px",
    },
    // [theme.breakpoints.down("md")]: {
    //   "& .MuiGrid-item": {
    //     // display: 'contents',
    //     // marginBottom: "20px",
    //     width: "37vh",
    //   },
    // },
    "& .MuiInput-root": {
      border: "1px solid #0386ee",
      // borderRadius: "10px",
      padding: "5px",
      // marginBottom: "20px",
      fontSize: "14px",
    },
    "& .MuiButton-contained.Mui-disabled": {
      color: "hsl(240deg 6% 87%)",
      boxShadow: "none",
      backgroundColor: "hsl(206deg 100% 73%)",
    },
    "& .MuiButton-contained": {
      color: "white",
      backgroundColor: "#0386ee",
      // borderRadius: "40px",
      height: "45px",
      width: "50%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiButton-contained-hover": {
      color: "white",
      backgroundColor: "#0386ee",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "#0386ee",
      border: "2px solid #0386ee",
      "& .loading-spinner ": {
        position: "absolute",
        right: "10%",
        width: "20px",
        height: "20px",
        border: "3px solid #0386ee",
        borderTop: "3px solid transparent" /* Blue */,
        borderRadius: "50%",
        animation: "spinner 1.5s linear infinite",
      },
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#0386ee",
    },
  },
  listName: {
    // margin: "10px 0px",
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 5px #ececec",
    color: "transparent",
    paddingTop: "2px",
    paddingBottom: "2px",
    "& .MuiListItemIcon-root": {
      minWidth: 0,
      "&:nth-child(5)": {
        display: "none",
      },
      [theme.breakpoints.down("xs")]: {
        "&:nth-child(5)": {
          display: "block",
        },
      },
    },
    "& .MuiListItemText-root": {
      marginLeft: 10,
      flex: 1,
    },
    "& a": {
      color: "#0386ee",
      textDecoration: "none",
    },
  },
  deviceName: {
    margin: "10px 0px",
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 5px #ececec",
    color: "transparent",
    "& .MuiListItemIcon-root": {
      minWidth: 0,
      "&:nth-child(5)": {
        display: "none",
      },
      [theme.breakpoints.down("xs")]: {
        "&:nth-child(5)": {
          display: "block",
        },
      },
    },
    "& .MuiListItemText-root": {
      marginLeft: 10,
      flex: 1,
    },
    "& a": {
      color: "#0386ee",
      textDecoration: "none",
    },
  },
  queueName: {
    margin: "10px 0px",
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 5px #ececec",
    color: "#0386ee",
    "& .MuiListItemIcon-root": {
      minWidth: 0,
      "&:nth-child(5)": {
        display: "none",
      },
      [theme.breakpoints.down("xs")]: {
        "&:nth-child(5)": {
          display: "block",
        },
      },
    },
    "& .MuiListItemText-root": {
      marginLeft: 10,
      flex: 1,
      [theme.breakpoints.down("xs")]: {
        "&:nth-child(2)": {
          display: "none",
        },
        "&:nth-child(3)": {
          display: "none",
        },
      },
    },
    "& a": {
      color: "#0386ee",
      textDecoration: "none",
    },
  },
  queueNameVisited: {
    margin: "10px 0px",
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 5px #ececec",
    color: "#0386ee",
    textDecoration: "line-through",
    "& .MuiListItemIcon-root": {
      minWidth: 0,
      "&:nth-child(5)": {
        display: "none",
      },
      [theme.breakpoints.down("xs")]: {
        "&:nth-child(5)": {
          display: "block",
        },
      },
    },
    "& .MuiListItemText-root": {
      marginLeft: 10,
      flex: 1,
      [theme.breakpoints.down("xs")]: {
        "&:nth-child(2)": {
          display: "none",
        },
        "&:nth-child(3)": {
          display: "none",
        },
      },
    },
    "& a": {
      color: "#0386ee",
      textDecoration: "none",
    },
  },
  queueNameCurrent: {
    margin: "10px 0px",
    // backgroundColor: "hsl(206deg 100% 88%)",
    backgroundColor: "#f5f2f2",
    border: "2px solid #0386ee",
    color: "#0386ee",
    "& .MuiListItemIcon-root": {
      minWidth: 0,
      "&:nth-child(5)": {
        display: "none",
      },
      [theme.breakpoints.down("xs")]: {
        "&:nth-child(5)": {
          display: "block",
        },
      },
    },
    "& .MuiListItemText-root": {
      marginLeft: 10,
      flex: 1,
      [theme.breakpoints.down("xs")]: {
        "&:nth-child(2)": {
          display: "none",
        },
        "&:nth-child(3)": {
          display: "none",
        },
      },
    },
    "& a": {
      color: "#0386ee",
      textDecoration: "none",
    },
  },
  manageQueue: {
    padding: "0px 35px",
    position: "relative",
    marginTop: "50px !important",
    flexDirection: "column",
    width: "max-content",
    maxWidth: "100vw",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
    },
    "& > div": {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      gap: "2em",
      // marginBottom: 15,
      [theme.breakpoints.down("xs")]: {
        display: "block",
        // textAlign: "center",
      },
      "& input": {
        border: "1px solid #0386ee",
        borderRadius: "10px",
        padding: 10,
        fontSize: "14px",
        outline: "none",
      },
      "& .durationPicker": {
        display: "flex",
        gap: 5,
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 36,
        },
        "& input": {
          width: 25,
          padding: "10px 5px",
          textAlign: "center",
          "-moz-appearance": "textfield",
          "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
      },
      "& p": {
        width: 200,
        [theme.breakpoints.down("xs")]: {
          // marginBottom: 5,
        },
      },
    },
    "& p": {
      textAlign: "center",
    },
    "& .errorMessage": {
      minHeight: 25,
      marginBottom: 0,
      "& p": {
        width: "100%",
        fontSize: 12,
        textAlign: "center",
      },
    },
    "& .whiteErrorMessage": {
      minHeight: 25,
      marginBottom: 0,
      color: "white",
      "& p": {
        width: "100%",
        fontSize: 12,
        textAlign: "center",
      },
    },
    "& .MuiButton-contained.Mui-disabled": {
      color: "hsl(240deg 6% 87%)",
      boxShadow: "none",
      backgroundColor: "hsl(206deg 100% 73%)",
    },
    "& .MuiButton-contained": {
      color: "white",
      backgroundColor: "#0386ee",
      borderRadius: "40px",
      height: "45px",
    },
    "& .MuiButton-contained-hover": {
      color: "white",
      backgroundColor: "#0386ee",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "#0386ee",
      border: "2px solid #0386ee",
    },
    "& .rc-time-picker-clear": {
      top: 9,
      right: 10,
    },
  },
  manageTime: {
    padding: "0px 35px",
    // position: "relative",
    // marginTop: "50px !important",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100vw",
    alignItems: "center",
    justifyContent: "center",
    bottom: "199px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
    },
    "& > div": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // gap: "2em",
      // marginBottom: 15,
      [theme.breakpoints.down("xs")]: {
        // display: "block",
        // textAlign: "center",
      },
      "& input": {
        border: "1px solid black",
        borderRadius: "10px",
        padding: 10,
        fontSize: "14px",
        outline: "none",
        color: "black",
      },
      "& .durationPicker": {
        display: "flex",
        gap: 5,
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 36,
        },
        "& input": {
          width: 25,
          padding: "10px 5px",
          textAlign: "center",
          "-moz-appearance": "textfield",
          "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
        "& .MuiFormLabel-root": {
          color: "black",
        },
      },
      "& p": {
        width: 154,
        [theme.breakpoints.down("xs")]: {
          // marginBottom: 5,
        },
      },
    },
    "& p": {
      textAlign: "center",
    },
    "& .errorMessage": {
      minHeight: 25,
      marginBottom: 0,
      "& p": {
        width: "100%",
        fontSize: 12,
        textAlign: "center",
      },
    },
    "& .MuiButton-contained.Mui-disabled": {
      color: "hsl(240deg 6% 87%)",
      boxShadow: "none",
      backgroundColor: "hsl(206deg 100% 73%)",
    },
    "& .MuiButton-contained": {
      color: "black",
      backgroundColor: "#0386ee",
      borderRadius: "40px",
      height: "45px",
    },
    "& .MuiButton-contained-hover": {
      color: "white",
      backgroundColor: "#0386ee",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "#0386ee",
      border: "2px solid #0386ee",
    },
    "& .rc-time-picker-clear": {
      top: 9,
      right: 10,
    },
  },
  currentCountChange: {
    display: "flex",
    justifyContent: "center",
    gap: "40px",
    marginTop: "120px",
    marginBottom: "10px",
    "& .MuiGrid-root": {
      "& .MuiFab-primary": {
        backgroundColor: "#060543",
        "& .MuiFab-label": {
          color: "white",
        },
      },
    },
  },
  searchBar: {
    marginTop: "-42px",
    justifyContent: "flex-end",
    display: "flex",
    marginRight: "40px",
    [theme.breakpoints.only("xs")]: {
      display: "none !important",
    },
  },
  headingDark: {
    color: "black",
    marginBottom: "1px",
    "& span": {
      fontWeight: "bold",
    },
  },
  dialogBox: {
    textAlign: "center",
    marginLeft: "0px",
    // "& .amplify-button amplify-field-group__control": {
    //   marginTop: "20px",
    //   backgroundColor: "#0386ee",
    //   borderRadius: "30px",
    //   width: "100px",
    //   height: "30px",
    //   color: "#fff",
    // },
    "& .MuiBox-root": {
      // textAlign: 'center'
      // marginLeft: '71px'
    },
    "& .MuiDialog-paper": {
      margin: 0,
      width: 353,
      height: "59vh",
    },
    "& .MuiDialogContent-root": {
      // padding: 10,
      // marginLeft: 67,
      // marginRight: 28,
      // marginTop: 53
    },
    "& svg": {
      fill: "#0386ee",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "36px",
    },
  },
  wifiList: {
    margin: "10px 0px",
    backgroundColor: "#f5f2f2",
    color: "#0386ee",
    justifyContent: "center !important",
    gap: "10px",
    "& .MuiListItemIcon-root": {
      minWidth: 0,
    },
    "& .MuiListItemText-root": {
      flex: "none !important",
    },
    "& .MuiListItem-root": {
      backgroundColor: "#fff",
    },
    "& img": {
      width: 15,
    },
  },
  wifiLoader: {
    position: "relative",
    top: "34vh",
    left: "50vw",
  },
  logIn: {
    "& .amplify-button--primary": {
      marginBottom: "60px !important",
    },
    "& div[data-amplify-router]": {
      marginTop: "0px !important",
    },
    background: "linear-gradient(180deg, #1A93F5 0%, #706DFB 100%)",
    maxWidth: "100vw",
    minHeight: "100vh",
  },
  CAuthenticator: {
    width: "100%",
    height: "100%",
  },
  signIn: {
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    backgroundColor: "#060543",
    height: "70px",
    /* align-self: center; */
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#fff",
    fontSize: 20,
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      // marginRight: 20,
      "& span": {
        fontSize: 30,
        color: "#fff",
        position: "absolute",
        left: "30px",
      },
    },
  },
  mainAnalytics: {
    // textAlign: "-webkit-center",
    "& .MuiListItem-root": {
      // cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f6f6f6f6",
      },
    },
    "& .MuiListItemText-primary": {
      textAlign: "center",
    },
  },
  wifiHeader: {
    textAlign: "center",
    "& h1": {
      color: "#086bf3",
      fontSize: 38,
    },
  },
  warning: {
    fontSize: 15,
    color: "red",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    backgroundColor: "#0386ee",
    boxShadow: 24,
    borderRadius: 10,
    textAlign: "center",
    "& .MuiInputBase-input": {
      color: "#000 !important",
    },
    "& Input": {
      // display: "none",
    },
    "& .css-i4bv87-MuiSvgIcon-root": {
      color: "#fff",
    },
    "& img": {
      marginTop: "20px",
      borderRadius: "50%",
    },
  },
  changePassword: {
    color: "#fff",
    padding: "20px 0px 0px 0px",
    backgroundColor: "white",
    textAlign: "center",
    "& .MuiFormControl-root": {
      padding: 10,
    },
    "& p": {
      textAlign: "center",
      color: "black",
      paddingBottom: 20,
    },
  },
  modalFooter: {
    // display: "flex",
    // flexDirection: "row-reverse",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "0px 0px 10px 10px",
  },
  modalFooter1: {
    display: "flex",
    flexDirection: "row-reverse",
    // backgroundColor: "white",
    // padding: "10px",
    // borderRadius: "0px 0px 10px 10px",
  },
  sessionStart: {
    "& .amplify-button--primary": {
      marginBottom: "60px !important",
    },
    "& div[data-amplify-router]": {
      marginTop: "0px !important",
    },
    background: "#fff",
    maxWidth: "100vw",
    minHeight: "100vh",
  },
  upperProfile: {
    backgroundColor: "#0586ee",
    color: "white",
    height: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      color: "red",
    },
    "& Input": {
      display: "none",
    },
    "& .css-i4bv87-MuiSvgIcon-root": {
      color: "#fff",
    },
    "& img": {
      borderRadius: "50%",
    },
  },
  cancelBtn: {
    "& button": {
      background: "#ed3333",
      color: "#fff",
    },
    "&:hover": {
      "& button": {
        background: "#fff",
        color: "#ed3333",
      },
    },
  },
}));
export default useStyles;
