import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useHistory, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import {
  getTotalCount,
  getUserImage,
  sendUserDetailsToSelectSlot,
  sendUserDetailsToConfirmSlot,
  getDoctorFormPreference,
  sendUserDetailsFormData,
  getDoctorFormUserPreference,
  getFormData,
} from "../api";
import { subscribeUser } from "../subscription";
import moment from "moment";
import Logo from "../assests/images/habilelabs_full_logo.svg";
import { Controller, useForm } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CAlert from "../component/CAlert";
import Alert from "@mui/material/Alert";
import { ReactComponent as UserRegistrationIcon } from "../assests/images/userRegistrationIcon.svg";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { HeaderMock } from "../component/HeaderMock";
import { colorStyles } from "../assests/css/color";
// import Grid from '@material-ui/core/Grid';
import { ReactComponent as ClockWithBlueBackground } from "../assests/images/ClockWithBlueBackground.svg";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { ReactSVG } from "react-svg";
import queueNotFound from "../../src/assests/icon/Queue-not-found.svg";

const useStyles = makeStyles((theme) => ({
  textfieldClass: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  logo: {
    color: "#fff",
    display: "flex",
    fontSize: "20px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#0386ee",
    backgroundColor: "#07174B",
    width: "100%",
    height: 80,
    // "& img": {
    //   width: 80,
    //   height: 80,
    //   borderRadius: "50%",
    //   backgroundColor: "#fff",import { makeStyles } from "@material-ui/core/styles";

    // },
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      marginRight: 20,
      "& span": {
        fontSize: 30,
        color: "#fff",
        position: "absolute",
        left: "30px",
      },
      "& IconButton": {
        fontSize: 30,
        color: "#fff",
      },
    },
  },
  root: {
    textAlign: "-webkit-center",
  },
  errorMessage: {
    "& div": {
      marginTop: "20px !important",
      zIndex: 12,
    },
  },
  loader: {
    top: "50%",
    position: "absolute",
    color: "#0386ee",
  },
  main: {
    // boxShadow: '0px 0px 1px #262F56',
    borderRadius: 10,
    width: "70%",
    padding: " 0px 15px",
    textAlign: "initial",
    boxShadow: "none",
    fontFamily: "'Manrope', 'sans-serif'",
    "& p": {
      marginBottom: 6,
      // alignSelf: "center",
    },
    "& .heading": {
      textAlign: "center",
      marginBottom: 20,
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },

    "& .MuiFormControl-fullWidth": {
      // marginBottom: 20,
      "& input": {
        paddingLeft: 7,
      },
    },
    "& .form-control": {
      width: "100%",
      height: 45,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiInput-root": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      // borderRadius: "10px",
      padding: "5px",
    },
    "& .react-tel-input .selected-flag, & .react-tel-input .flag-dropdown.open .selected-flag":
      {
        borderRadius: 20,
      },
    "& .react-tel-input .form-control.invalid-number": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #0386ee",
      "&:focus": {
        border: "1px solid #0386ee",
      },
    },
    "& .react-tel-input .flag-dropdown": {
      border: "1px solid #0386ee",
      borderRadius: "10px 0px 0px 10px",
    },
    "& .react-tel-input .form-control": {
      border: "1px solid #0386ee",
      borderRadius: "10px",
    },
    "& .react-tel-input .invalid-number-message": {
      position: "absolute",
      zIndex: "1",
      fontSize: "0.75rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      left: 0,
      marginTop: 4,
      top: "46px",
      color: "#f44336",
    },
    "& .MuiButton-contained.Mui-disabled": {
      color: "hsl(240deg 6% 87%)",
      boxShadow: "none",
      backgroundColor: "hsl(206deg 100% 73%)",
    },
    "& .MuiButton-contained": {
      color: "white",
      backgroundColor: "#0386ee",
      // borderRadius: "40px",
      height: "55px",
      marginTop: 10,
      marginBottom: 15,
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "#0386ee",
      border: "2px solid #0386ee",
    },
    "& .MuiGrid-spacing-xs-3 > .MuiGrid-item": {
      padding: "0px 10px",
      marginBottom: "20px",
    },
  },
  // inputError: {
  //   border: "1px solid #f44336 !important",
  // },
  warning: {
    color: "red",
    fontSize: 10,
  },
  powered: {
    textAlign: "right",
    margin: 20,
    "& span": {
      color: "#0386ee",
    },
  },
  "e-input-focus": {
    border: "1px solid black !important",
  },
  slotBtn: {
    marginTop: "20px",
    padding: 5,
    display: "inline-block",
    "& button": {
      backgroundColor: "#e3ffe9",
      color: "black",
    },
  },
  calendraPaper: {
    // top: "70px !important",
    // left: "90% !important",
    transform: "translate(-100%, 0) !important",
    // "@media screen and (max-width: 1080px)": {},
    // "@media screen and (max-width: 1280px)": {
    //   transform: "translate(-90%, 0) !important",
    // },
  },
}));

const UserSignUp = () => {
  const [minDateCalender, setMinDateCalender] = useState("");
  const [maxDateCalender, setMaxDateCalender] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const { device } = useParams();
  const id = atob(device);
  const [deviceLogo, setDeviceLogo] = useState(Logo);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalNumber] = useState(0);
  const [value, setValue] = useState({ minDateCalender });
  let [queueName, setQueueName] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [checked, setChecked] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [slotData, setslotData] = useState();
  const [name, setName] = useState();
  const [contact, setContact] = useState();
  const [slotDate, setSlotDate] = useState();
  const [notificationID, setnotificationID] = useState();
  const [selectedSlot, setselectedSlot] = useState();
  const [activeButton, setActiveButton] = useState("");
  const [isSlotSelected, setisSlotSelected] = useState(false);
  const [slotAlreadyBooked, setSlotAlreadyBooked] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [calenderOpen, setcalenderOpen] = useState(false);
  const [userFormField, setuserFormField] = useState([]);
  const [blockDates, setBlockDates] = useState([]);
  const [userDatas, setUserDatas] = useState([]);
  const [flexibleDays, setFlexibleDays] = useState(0);
  const [IsStateUpdateing, setIsStateUpdateing] = useState(false);
  const [formData, setFormData] = useState({});
  const [isQueueDeleted, setisQueueDeleted] = useState(false);

  const [isRegistrationClosedToday, setisRegistrationClosedToday] =
    useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    setIsLoading(true);
    const apiHandler = async () => {
      // console.log("responseDatafromuserSignUp1")
      const responseData = await getDoctorFormPreference(id);
      setuserFormField(responseData);
      setIsLoading(false);
    };
    apiHandler();
  }, [id]);

  useEffect(() => {
    getDoctorFormUserPreference(id).then((res) => {
      if (res.statusCode === 201) {
        setisQueueDeleted(true);
        return;
      }
      res.data.Block_Dates && setBlockDates(res.data.Block_Dates);
      res.data.Felxible_Days && setFlexibleDays(res.data.Felxible_Days);
      setBlockDates(res.data.Block_Dates);
      setFlexibleDays(res.data.Felxible_Days);
    });
  }, [id]);

  useEffect(() => {
    const data = {
      Device_Name: id + "_FormData",
    };
    getFormData(data).then((res) => {
      setUserDatas(res.formData.Form_Field);
    });
  }, []);

  useEffect(() => {
    setMaxDateCalender(moment().add(flexibleDays, "days").format("YYYY-MM-DD"));
  }, [flexibleDays]);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const anchor = document.getElementsByClassName("MuiIconButton-edgeEnd");

  const handleChangeDate = (newValue) => {
    setActiveButton("");
    setSlotAlreadyBooked(false);
    setisSlotSelected(false);

    const valueOfInput = newValue && newValue.format("YYYY-MM-DD");
    setSlotDate(valueOfInput);
    setValue(newValue);
    sendUserDetailsToSelectSlotApiCAll(valueOfInput);
    setAlert({ message: "", type: "" });
  };

  React.useEffect(() => {
    setValue(moment().add(0, "days").format("YYYY-MM-DD"));
    setMinDateCalender(moment().add(0, "days").format("YYYY-MM-DD"));
    setSlotDate(moment().add(0, "days").format("YYYY-MM-DD"));
    sendUserDetailsToSelectSlotApiCAll(
      moment().add(0, "days").format("YYYY-MM-DD")
    );
    // if (moment(new Date()).format("YYYY-MM-DD") == "2023-02-16") {
    //   console.log("htmlcontentyui16");
    //   setValue("2023-02-17");
    //   setMinDateCalender("2023-02-17");
    //   setSlotDate("2023-02-17");
    //   sendUserDetailsToSelectSlotApiCAll("2023-02-17");
    // } else if (moment(new Date()).format("YYYY-MM-DD") == "2023-02-17") {
    //   console.log("htmlcontentyui17");
    //   setValue("2023-02-18");
    //   setMinDateCalender("2023-02-18");
    //   setSlotDate("2023-02-18");
    //   sendUserDetailsToSelectSlotApiCAll("2023-02-18");
    // } else if (moment(new Date()).format("YYYY-MM-DD") == "2023-02-18") {
    //   console.log("htmlcontentyui18");
    //   setValue("2023-02-19");
    //   setMinDateCalender("2023-02-19");
    //   setSlotDate("2023-02-19");
    //   sendUserDetailsToSelectSlotApiCAll("2023-02-19");
    // } else {
    //   setValue(moment().add(1,'days').format("YYYY-MM-DD"));
    //   setMinDateCalender(moment().add(1,'days').format("YYYY-MM-DD"));
    //   setSlotDate(moment().add(1,'days').format("YYYY-MM-DD"));
    //   sendUserDetailsToSelectSlotApiCAll(
    //     moment().add(1,'days').format("YYYY-MM-DD")
    //   );
    // }
    // else {
    //   setMinDateCalender(moment(new Date()).format("YYYY-MM-DD"));
    // }
    sendUserDetailsToSelectSlotApiCAll(
      moment().add(0, "days").format("YYYY-MM-DD")
    );
    setSlotDate(moment().add(0, "days").format("YYYY-MM-DD"));
  }, [minDateCalender]);

  React.useEffect(() => {
    getTotalCount(id)
      .then((res) => {
        setTotalNumber(res);
      })
      .catch((err) => {
        console.log(err.message);
      });
    let q = id.split("_")[2];
    if (q) {
      setQueueName(id.split("_")[2]);
      console.log(q);
    }
  }, []);
  const sendUserDetailsToSelectSlotApiCAll = (date) => {
    // setage(month);

    let userDetails = {
      deviceName: id,
      name: name,
      contact: contact,
      timeSlot: date,
      notificationId: notificationID,
    };

    // const timeSlot = value.format("YYYY-MM-DD/HH:mm:ss");
    // setSlotDate(value.format("YYYY-MM-DD"));
    // userDetails.timeSlot = timeSlot;
    sendUserDetailsToSelectSlot(userDetails)
      .then((res) => {
        setslotData(res.data);
        let data = userDetails;
        setIsLoading(false);
        if (res.statusCode == 201) {
          {
            // setAlert({ message: error.message, type: 'error' });
            name && setAlert({ message: res.message, type: "error" });
          }

          // setAlert({ message: res.message, type: "error" });
        } else if (res.statusCode == 202) {
          setisRegistrationClosedToday(true);

          // setAlert({ message: res.message, type: "error" });
        } else {
          data.id = device;
          // data.date = res.Data.Date_Time;
          data.Time_Slot = res.Time_Slot;
          localStorage.setItem(id, JSON.stringify(data));
          localStorage.setItem("id", JSON.stringify(data));
          // history.push({
          //   pathname: `/RegistrationSuccess/${device}`,
          //   state: { count: totalCount + 1 },
          // });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const sendUserDetailsToConfirmSlotApiCall = () => {
    subscribeUser().then((notification) => {
      console.log("data==_-----------", notification);

      setnotificationID(notification);
      let userDetails = {
        deviceName: id,
        // name: name,
        // contact: contact,
        timeSlot: slotDate + "/" + selectedSlot,
        notificationId: notification,
        formData: formData,
      };
      // let userDetails = {
      //   deviceName: id,
      //   date,
      //   notificationId: notification,
      //   formData: formData,
      // };
      sendUserDetailsToConfirmSlot(userDetails)
        .then((res) => {
          if (res.statusCode == 200) {
            console.log("insidedata1")
            let currentDate = moment(res.data.Date_Time).format("HH:mm:ss");
            console.log("insidedata2")

            localStorage.setItem(`${id}_dateTime`, currentDate);
            console.log("insidedata3")

            history.push({
              pathname: `/RegistrationSuccess/${device}`,
              state: {
                confirmTimeSlot: res.data.Date_Time,
                queueName: res.data.Device_Name.split("_")[2],
              },
            });
          
          } else if (res.statusCode == 201) {
            {
              // setAlert({ message: error.message, type: 'error' });
              setisSlotSelected(false);
              name && setAlert({ message: res.message, type: "error" });
            }
            // setErrorMessage(res.message);
            // console.log("next page 201");
            // console.log(res);
            // setSlotAlreadyBooked(true);
            // setAlert({ message: res.message, type: 'error' });

            // <CAlert
            //   message={"Slot Already Booked"}
            //   type={"error"}
            //   setAlert={setAlert}
            // />;

            sendUserDetailsToSelectSlotApiCAll(slotDate);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };

  React.useEffect(() => {
    if (id) {
      getUserImage(id.split("_")[0])
        .then((res) => {
          const logoLink = res.files.link;
          setDeviceLogo(logoLink);
          // setImg(imageData.files.link)
          localStorage.setItem("img", logoLink);
        })
        .catch((err) => console.log(err));
    }
  }, [id, deviceLogo]);

  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmit = async (data) => {
    console.log("data :", data);
    console.log("formData", formData);
    console.log("age", year, "Y", month, "M");
    // const formDataStringify = JSON.stringify(formData);
    // const forDataText = formDataStringify.replace(/[{}]/g, "");

    // console.log(text);
    setName(data.name);
    setContact(`${91}${data.phone}`);
    formData["name"] = data.name;
    formData["contact"] = `${91}${data.phone}`;
    (year || month) && (formData["Age"] = `${year || 0}Y${month || 0}M`);
    setIsStateUpdateing(true);
    // setFormData((prevData) => ({
    //   ...prevData,
    //   ["name"]: data.name,
    // }));
    // setFormData((prevData) => ({
    //   ...prevData,
    //   ["contact"]: `${91}${data.phone}`,
    // }));
    // setIsStateUpdateing(false);
    // (year || month) &&
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     ["Age"]: `${year || 0}Y${month || 0}M`,
    //   }));

    // formData["Age"] = );
    console.log("formData1", formData);

    if (!IsStateUpdateing) {
      console.log("formData2", formData);
    }
    console.log("formData3", formData);

    // let userFormDataForApi = {
    //   name: data.name,
    //   phone: data.phone,
    //   ...((year || month) && { Age: `${year  || 0}Y${month || 0}M` }),
    //   formData,
    // };

    // console.log("userFormDataForApi", userFormDataForApi);

    // setage(data.Age);
    // // setgender(data.gender);
    // setemergency(data.emergency);
    // setDiseaseName(data.DiseaseName);
    // setAddress(data.Address);
    if (checked && !showCalendar) return setShowCalendar(true);

    subscribeUser().then((notification) => {
      console.log("data==_-----------", notification);

      setIsLoading(true);
      const date = moment(new Date()).format("YYYY-MM-DD/HH:mm:ss:SSSS");
      setnotificationID(notification);
      let userDetails = {
        deviceName: id,
        date,
        notificationId: notification,
        formData: formData,
      };
      console.log("data==_-----------", userDetails);

      if (checked && showCalendar) {
        let formData = userDetails;
        const timeSlot = value.format("YYYY-MM-DD/HH:mm:ss");
        setSlotDate(value.format("YYYY-MM-DD"));
        formData.timeSlot = timeSlot;
        console.log("checked && showCalendar1", formData);
        sendUserDetailsToSelectSlot(formData);
        console
          .log("checked && showCalendar1")
          .then((res) => {
            setslotData(res.data);
            let data = userDetails;
            setIsLoading(false);

            if (res.statusCode == 201) {
              setAlert({ message: res.message, type: "error" });
            } else if (res.statusCode == 202) {
              setisRegistrationClosedToday(true);

              setAlert({ message: res.message, type: "error" });
            } else {
              data.id = device;
              // data.date = res.Data.Date_Time;
              data.Time_Slot = res.Time_Slot;
              localStorage.setItem(id, JSON.stringify(data));
              // localStorage.setItem("id", JSON.stringify(data));

              // history.push({
              //   pathname: `/RegistrationSuccess/${device}`,
              //   state: { count: totalCount + 1 },
              // });
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        // let formDataDirectFlow = {
        //   deviceName: id,
        //   date,
        //   notificationId: notification,
        //   formData: formData,
        // };
        // console.log("formDataDirectFlow", formDataDirectFlow);
        // let userDetailsData = formDataDirectFlow;
        // userDetailsData.contact = `${91}${userDetails.contact}`;

        // setContact(`${91}${data.phone}`);
        sendUserDetailsFormData(userDetails)
          .then((response) => {
            if (response.status === 202) {
              setisRegistrationClosedToday(true);

              // setErrorMessage("queue registration closed for today");
              setAlert({
                message: "queue is closed",
                type: "",
              });
              setTimeout(() => {
                setAlert({ message: "", type: "" });
                setisRegistrationClosedToday(false);
              }, 2500);
              setIsLoading(false);
              return;
            } else {
              const res = response.data;
              let data = userDetails;
              data.count = res.Data.Count;
              data.id = device;
              data.date = res.Data.Date_Time;
              data.Your_Time = res.Your_Time;
              data.Your_Name = res.Data.Name;
              localStorage.setItem("id", JSON.stringify(data));
              localStorage.setItem(id, JSON.stringify(data));

              let currentDate = moment(res.Data.Date_Time).format("HH:mm:ss");
              localStorage.setItem(`${id}_dateTime`, currentDate);

              // localStorage.setItem("id", JSON.stringify(data));

              setIsLoading(false);
              history.push({
                pathname: `/token/${device}`,
                state: {
                  count: totalCount + 1,
                  time: res.Data.Date_Time,
                  yourName: res.Data.Name,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    });
  };
  // const today = moment(new Date()).format("YYYY/MM/DD");
  // console.log("today :", today);

  const disableWeekends = (date) => {
    const convertedIntoDateObject =
      blockDates &&
      blockDates.map((bookedDate) => {
        return moment(new Date(bookedDate)).format("YYYY-MM-DD");
      });
    return (
      convertedIntoDateObject &&
      convertedIntoDateObject.includes(
        moment(new Date(date.$d)).format("YYYY-MM-DD")
      )
    );
  };

  // useEffect(() => {
  //   const userDetails = JSON.parse(localStorage.getItem(id));
  //   if(!userDetails.Your_Name){
  //     history.push({
  //       pathname: `/tr/${device}`
  //     });
  //     console.log("history.push")
  //   }
  //   userDetails.Your_Name && console.log("userDetailsUseEffect", userDetails.Your_Name);
  //   console.log("userDetailsUseEffect", userDetails);
  // }, []);
  const Error = ({ children }) => (
    <p style={{ marginLeft: "15px", color: "red" }}>{children}</p>
  );
  const ErrorWithoutMap = ({ children }) => (
    <p style={{ color: "red" }}>{children}</p>
  );
  console.log("formDataformData", formData);
  console.log("formDataformData", formData.testNumber);
  //  formData.testNumber ?  console.log("formDataformData", formData.testNumber) : null
  return (
    <div className={classes.root}>
      <div className={classes.errorMessage}>
        {alert.message !== "" && (
          <CAlert
            message={alert.message}
            type={alert.type}
            setAlert={setAlert}
          />
        )}
      </div>
      <HeaderMock />

      {slotAlreadyBooked ? (
        <Alert severity="error">{errorMessage}</Alert>
      ) : null}
      {isRegistrationClosedToday && alert.message !== "" ? (
        <Alert severity="error">{errorMessage}</Alert>
      ) : null}

      {isLoading ? (
        <CircularProgress className={classes.loader} />
      ) : (
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className={classes.main}
          onSubmit={handleSubmit(onSubmit)}
        >
          {isQueueDeleted ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "25%",
                }}
              >
                <ReactSVG src={queueNotFound} />

                <p>Queue does not exist.</p>
              </div>
            </>
          ) : (
            <>
              {showCalendar ? (
                <>
                  <Grid
                    container
                    xs={12}
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <p>
                        Congratulation! You are successfully enrolled for{" "}
                        <span
                          style={{ fontWeight: "bolder", fontSize: "large" }}
                        >
                          {queueName}{" "}
                        </span>
                        Please select slot to confirm the visit.
                      </p>
                      <ClockWithBlueBackground
                        style={{ marginBottom: "15px", alignSelf: "center" }}
                      />
                    </div>

                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          open={calenderOpen}
                          onOpen={() => setcalenderOpen(true)}
                          onClose={() => setcalenderOpen(false)}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          // const format1 = "YYYY-MM-DD HH:mm:ss"
                          // const format2 = "YYYY-MM-DD"
                          // var date1 = new Date("2020-06-24 22:57:36");
                          // var date2 = new Date();

                          // dateTime1 = moment(date1).format(format1);
                          // dateTime2 = moment(date2).format(format2);
                          // disablePast
                          PaperProps={{
                            sx: {
                              "& .MuiPickersDay-root": {
                                fontWeight: 900,
                              },
                            },
                          }}
                          PopperProps={{
                            placement: "bottom-end",
                            // className: classes.calendraPaper,
                          }}
                          popperModifiers={{
                            flip: {
                              behavior: ["bottom"], // don't allow it to flip to be above
                            },
                            preventOverflow: {
                              enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                              enabled: false, // turn off since needs preventOverflow to be enabled
                            },
                          }}
                          minDate={minDateCalender}
                          maxDate={maxDateCalender}
                          inputFormat="DD MMM YYYY"
                          value={value}
                          onChange={handleChangeDate}
                          shouldDisableDate={disableWeekends}
                          // onChange={(e) => {
                          //   e.preventDefault();
                          //   sendUserDetailsToSelectSlotApiCAll(e.target.value);
                          // }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              readOnly={true}
                              onClick={(e) => setcalenderOpen(true)}
                              editable={false}
                              onKeyDown={(event) => {
                                event.preventDefault();
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {/* <Grid> */}
                    {/* <Box style={{}}> */}
                    <div
                      style={
                        slotData
                          ? {
                              width: "-webkit-fill-available",
                              maxHeight: "42vh",
                              overflowY: "scroll",
                            }
                          : {}
                      }
                    >
                      {slotData ? (
                        <>
                          <Box
                            style={{
                              display: "inline-block",
                              textAlign: "center",
                            }}
                          >
                            {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        > */}
                            {/* <Grid
                            container
                            spacing={2}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          > */}
                            {slotData.map((slot) => {
                              return (
                                <div className={classes.slotBtn}>
                                  {/* <Grid item xs={12} sm={6} md={3}> */}
                                  {/* <Item> */}
                                  <button
                                    key={slot}
                                    style={{
                                      borderColor:
                                        activeButton === slot ? "" : "#489D00",
                                      backgroundColor:
                                        activeButton === slot
                                          ? "#0C9F00"
                                          : "#ffff ",
                                      padding: "10px",
                                      paddingLeft: "40px",
                                      paddingRight: "40px",
                                      marginLeft: "10px",
                                      border: "1px solid #0C9F00",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setisSlotSelected(true);

                                      setselectedSlot(slot);
                                      setActiveButton(slot);

                                      // sendUserDetailsToConfirmSlotApiCall(slot);
                                    }}
                                  >
                                    {slot}
                                    {/* {moment(slot  , "HH:mm:ss").format("HH:mm")} */}
                                  </button>
                                  {/* </Item> */}
                                  {/* </Grid> */}
                                </div>
                              );
                            })}
                            {/* </Grid> */}
                            {/* </div> */}
                          </Box>
                        </>
                      ) : (
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {console.log("herere!slotData")}

                          {isRegistrationClosedToday ? (
                            <Typography>Queue is closed for today</Typography>
                          ) : (
                            <Typography>
                              Slot Not Available for this Date Please Select
                              Another Date
                            </Typography>
                          )}
                        </div>
                      )}
                    </div>
                    {/* </Box> */}
                    {/* </Grid> */}

                    {isSlotSelected && slotData ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "fixed",
                          bottom: "0",
                          paddingBottom: "10px",
                          backgroundColor: "white",
                          height: "55px",
                          width: "100%",
                        }}
                      >
                        <Button
                          onClick={() => {
                            sendUserDetailsToConfirmSlotApiCall();
                          }}
                          style={{
                            color: "white",
                            height: "45px",
                            marginTop: "15px",
                            borderRadius: "40px",
                            backgroundColor: colorStyles.primaryBackgroundColor,
                            width: "40%",
                            alignSelf: "center",
                            marginBottom: "10px",
                          }}
                        >
                          Confirm
                        </Button>
                      </div>
                    ) : null}
                  </Grid>
                </>
              ) : (
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      className="heading"
                    >{`Enroll yourself in the Queue of ${
                      queueName ? queueName : ""
                    } to get update about your turn.`}</Typography>
                    {/* <Typography
                  variant="h6"
                  className="heading"
                >{`Enroll yourself to visit us at CII Exhibition, New Delhi. Meeting details will be shared on your phone.`}</Typography> */}
                  </Grid>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      width: "100%",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <UserRegistrationIcon />
                  </div>
                  <Grid item xs={12}>
                    {/* <Typography>Name</Typography> */}
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: "Name is Required",
                        },
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Only Character Allowed",
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          // error={!!error}
                          id="outlined-basic"
                          label="Full Name"
                          helperText={
                            error ? (
                              <ErrorWithoutMap>{error.message}</ErrorWithoutMap>
                            ) : (
                              " "
                            )
                          }
                          variant="outlined"
                          fullWidth
                          // placeholder={"Full Name"}
                          value={value}
                          onChange={onChange}
                          // error={!!error}
                          // helperText={error ? error.message : " "}
                          autoComplete={false}
                          InputProps={{
                            disableUnderline: true,
                            // className: error ? classes.inputError : "",
                          }}
                          // required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: "Phone Number is Required",
                        },
                        minLength: {
                          value: 10,
                          message: "Please enter a valid 10 digit number",
                        },
                        pattern: {
                          value: /^(?:91?|[0]?)?[6789]\d{9}$/,
                          message: "Invalid mobile number",
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          className={classes.textfieldClass}
                          // error={!!error}
                          id="outlined-basic"
                          label="Phone Number"
                          helperText={
                            error ? (
                              <ErrorWithoutMap>{error.message}</ErrorWithoutMap>
                            ) : (
                              " "
                            )
                          }
                          variant="outlined"
                          fullWidth
                          type="number"
                          // placeholder={"Full Name"}
                          value={value}
                          onChange={onChange}
                          // error={!!error}
                          // helperText={error ? error.message : " "}
                          autoComplete={false}
                          InputProps={{
                            disableUnderline: true,

                            // className: error ? classes.inputError : "",
                          }}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div>
                      {userDatas &&
                        userDatas.map((field, index) => (
                          <Grid item xs={12} style={{ marginBottom: "24px" }}>
                            {field.type === "text" && (
                              <TextField
                                variant="outlined"
                                fullWidth
                                type="text"
                                className={classes.textfieldClass}
                                id="outlined-basic"
                                name={field.fieldName}
                                onChange={(value) => {
                                  handleChangeFormData(value);
                                }}
                                label={field.fieldName}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            )}
                            {field.type === "number" && (
                              <TextField
                                variant="outlined"
                                fullWidth
                                type="number"
                                className={classes.textfieldClass}
                                id="outlined-basic"
                                name={field.fieldName}
                                onChange={(value) => {
                                  handleChangeFormData(value);
                                }}
                                // onChange={handleChangeFormData}
                                label={field.fieldName}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            )}
                            {/* {field.type === "number" &&
                          (!field.fieldName === "Age" ||
                            !field.fieldName === "age") && (
                            <TextField
                              variant="outlined"
                              type="number"
                              fullWidth
                              className={classes.textfieldClass}
                              id="outlined-basic"
                              name={field.fieldName}
                              onChange={handleChangeFormData}
                              label={field.fieldName}
                              InputProps={{
                                disableUnderline: true,
                              }}
                            />
                          )} */}
                            {field.type === "checkbox" && (
                              <FormControl
                                required
                                component="fieldset"
                                marginRight="10px"
                                rules={{
                                  required: {
                                    value: true,
                                    message: `${field.fieldName} is Required`,
                                  },
                                }}
                              >
                                <FormLabel component="legend">
                                  {field.fieldName}
                                </FormLabel>
                                <FormGroup>
                                  {field.options.map((option, optionIndex) => (
                                    <FormControlLabel
                                      key={optionIndex}
                                      control={
                                        <Checkbox value={option.value} />
                                      }
                                      label={option.value}
                                      onChange={(value) => {
                                        handleChangeFormData(value);
                                      }}
                                      // onChange={handleChangeFormData}
                                      name={field.fieldName}
                                    />
                                  ))}
                                </FormGroup>
                              </FormControl>
                            )}
                            {field.type === "select" && (
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  {field.fieldName}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  label={field.fieldName}
                                  variant="outlined"
                                  fullWidth
                                  onChange={(value) => {
                                    handleChangeFormData(value);
                                  }}
                                  // onChange={handleChangeFormData}
                                  name={field.fieldName}
                                  style={{ textDecoration: "none" }}
                                >
                                  {field.options.map((option, optionIndex) => (
                                    <MenuItem
                                      key={optionIndex}
                                      value={option.value}
                                    >
                                      {option.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                            {(field.fieldName === "Age" ||
                              field.fieldName === "age") && (
                              <>
                                <p>Age</p>
                                <div>
                                  <Controller
                                    name="Age"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: {
                                        value: true,
                                        message: "Age is Required",
                                      },
                                    }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <div
                                        style={{
                                          marginTop: "5px",
                                          display: "flex",
                                        }}
                                      >
                                        <TextField
                                          style={{ marginRight: "10px" }}
                                          className={classes.textfieldClass}
                                          id="outlined-basic"
                                          label="In year"
                                          variant="outlined"
                                          type="number"
                                          fullWidth
                                          name={field.fieldName}
                                          defaultValue="0"
                                          onChange={(value) => {
                                            onChange(value);
                                            setYear(value.target.value);
                                          }}
                                          helperText={
                                            error &&
                                            !(
                                              year.length > 0 ||
                                              month.length > 0
                                            ) ? (
                                              <ErrorWithoutMap>
                                                {error.message}
                                              </ErrorWithoutMap>
                                            ) : (
                                              " "
                                            )
                                          }
                                        />
                                        <TextField
                                          className={classes.textfieldClass}
                                          id="outlined-error-helper-text"
                                          label="In month"
                                          variant="outlined"
                                          type="number"
                                          name={field.fieldName}
                                          fullWidth
                                          defaultValue="0"
                                          onChange={(value) => {
                                            onChange(value);
                                            setMonth(value.target.value);
                                          }}
                                        />
                                      </div>
                                    )}
                                  />
                                </div>
                              </>
                            )}
                          </Grid>
                        ))}
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 5 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Typography style={{ marginTop: "7px" }}>
                        Select Date and Time Slot
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 5 }}>
                    <div>
                      <Button variant="contained" fullWidth type="submit">
                        {checked ? "Next" : "Confirm"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Box>
      )}
    </div>
  );
};

export default UserSignUp;
