// import * as React from "react";
import React, { useState } from "react";
import {
  CircularProgress,
  Fab,
  Grid,
  TextField,
} from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";
import useStyles from "../assests/css/styles";
import { getAnalyticList, getCurrentCount } from "../api";
import moment from "moment";
import MQTTConnection from "../services/MqttConnection";
import MQTTConnectionList from "../services/MqttConnectionList";
import MqttDeleteConnection from "../services/MqttDeleteConnection";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Header } from "../component/HeaderWithoutBack";
import AnalyticsItem from "./AnalyticsItem";

const AnalyticsPage = () => {
  const [minDateCalender, setMinDateCalender] = useState("");
  const [value, setValue] = useState({ minDateCalender });
  const classes = useStyles();
  const location = useLocation();
  let { id } = useParams();
  const device = atob(id);
  const ref = React.useRef(null);
  const count = location.state && location.state.count;
  const [isLoading, setIsLoading] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [newList, setNewList] = React.useState({});
  const [currentNumber, setCurrentNumber] = React.useState(0);
  const [mqttClient, setMqttClient] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()).format("YYYY-MM-DD"));
  const [products, setProducts] = useState(list);
  const [searchInput, setSearchInput] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const countChange = list && list.length && list.map((item) => item.Count);

  const [dateValue, setDateValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  // useEffect(() => {
  //   newMqttClient.on("connect", function () {
  //     // setIsConnected(true);
  //     newMqttClient.subscribe(device_App);
  //     newMqttClient.subscribe(totalCountTopic.replace('.', ''));
  //     // console.log("Publisher connected to AWS IoT for clientId:", clientId);
  //   });
  // }, []);

  React.useEffect(() => {
    getAnalyticListHandler();
  }, []);
  const getAnalyticListHandler = () => {
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    setIsLoading(true);
    getAnalyticList(device, `${todayDate}`)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setList(res.data.Data);
          setProducts(res.data.Data);
        }
      })
      .catch((err) => console.log(err));
  };
  const getAnalyticListHandlerWIthoutReload = () => {
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    getAnalyticList(device, `${todayDate}`)
      .then((res) => {
        if (res.status === 200) {
          setList(res.data.Data);
          setProducts(res.data.Data);
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    if (Object.keys(newList).length > 0 && newList.constructor === Object) {
      const data = [...list, newList];
      setList(data.sort((a, b) => b - a));
    }
  }, [newList]);

  React.useEffect(() => {
    if (count) {
      setCurrentNumber(Number(count));
    } else {
      getCurrentCount(device)
        .then((res) => {
          setCurrentNumber(res.currentNum);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  const handleCountChange = (change) => {
    const data = {
      deviceName: device,
    };
    if (change === "increase") {
      const number = Number(currentNumber) + 1;
      setCurrentNumber(number);
      data.count = number;
    }
    if (change === "decrease") {
      const number =
        Number(currentNumber) > 0
          ? Number(currentNumber) - 1
          : Number(currentNumber);
      setCurrentNumber(number);
      data.count = number;
    }

    const newIdnewId = {
      Device_Name: `${device}_Device`,
      Date_Time: moment(new Date()).format("YYYY-MM-DD/HH:mm:ss:SSSS"),
      Count: data.count.toString(),
    };
    mqttClient.publish(`${device}_Device`, JSON.stringify(newIdnewId));
  };

  const handleChangeDate = (newValue) => {
    setDateValue(newValue.format("YYYY-MM-DD"));
    setIsLoading(true);
    setSelectedDate(newValue.format("YYYY-MM-DD"));
    getAnalyticList(device, newValue.format("YYYY-MM-DD"))
      .then((res) => {
        if (res.status === 200) {
          setList(res.data.Data);
          setProducts(res.data.Data);
          setIsLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  React.useEffect(() => {
    if (searchInput && searchInput.length > 0) {
      const data = list.filter((item) => {
        return item.Name.toLowerCase().includes(searchInput.toLowerCase());
      });
      setProducts(data);
      if (searchInput === "") {
        setProducts(list);
      }
    } else {
      getAnalyticList(device, dateValue)
        .then((res) => {
          if (res.status === 200) {
            setProducts(res.data.Data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [searchInput]);
  const removeUserHandler = () => {
    getAnalyticListHandlerWIthoutReload();
  };
  console.log("Subscribedtodata",products)

  return (
    <div className={classes.mainAnalytics}>
      <div>
        <MQTTConnection
          totalCountTopic={`${device}_App`}
          setTotalNumber={setNewList}
          currentCountTopic={`${device}_Device`}
          setCurrentNumber={setCurrentNumber}
          setMqttClient={setMqttClient}
        />
        <MQTTConnectionList
          totalCountTopic={`${device}_App`}
          // setTotalNumber={setNewList}
          // currentCountTopic={`${device}_Device`}
          // setCurrentNumber={setCurrentNumber}
          // setMqttClient={setMqttClient}
          setProducts={setProducts}
          setList={setList}
        />
        <MqttDeleteConnection
          totalCountTopic={`${device}_Delete`}
          removeUserHandler={removeUserHandler}
        />
        <div>
          <Header />
        </div>
      </div>
      <div className={classes.calenderSection}></div>
      {!isLoading ? (
        <>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {" "}
              <DesktopDatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                className={classes.positionMobile}
                InputProps={{
                  disableUnderline: true,
                }}
                PopperProps={{ placement: "bottom-end" }}
                // minDate={moment().format("YYYY-MM-DD")}
                minDate={minDateCalender}
                inputFormat="DD MMM YYYY"
                value={dateValue}
                onChange={handleChangeDate}
                // onChange={(e) => {
                //   e.preventDefault();
                //   sendUserDetailsToSelectSlotApiCAll(e.target.value);
                // }}
                // MuiSvgIcon-root
                renderInput={(params) => (
                  <TextField
                    {...params}
                    readOnly={true}
                    onClick={(e) => setOpen(true)}
                    editable={false}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                  />
                )}
                // popperPlacement="bottom-start"
              />
            </LocalizationProvider>
            <span className={classes.searchBar}>
              <input
                type="text"
                placeholder="Search"
                onChange={handleSearchChange}
                value={searchInput}
                style={{ padding: "7px" }}
              />
            </span>
            {/* <ListItem
              className={classes.queueName + " " + classes.headingDark}
              component="div"
            >
              <ListItemText primary="Name" />
              <ListItemText primary="Contact No." />
              <ListItemText primary="Queue No." />
              <ListItemText primary="Arrival at" />
            </ListItem> */}
          </div>
          {products && products.length  ? (
            <>
            {/* {console.log("analyticdata", products)} */}

              <AnalyticsItem
                elemRef={ref}
                data={products}
                currentNumber={currentNumber}
              />
              <div className={classes.currentCountChange}>
              {console.log("selectedDate",selectedDate)}
              {console.log("selectedDate",moment(new Date()).format("YYYY-MM-DD"))}
                {countChange[0] > 0 && selectedDate === moment(new Date()).format("YYYY-MM-DD") ? (
                  <>
                    <Grid item>
                      <Fab
                        color="primary"
                        aria-label="add"
                        disabled={currentNumber <= 1}
                        onClick={() => handleCountChange("decrease")}
                      >
                        <RemoveIcon color="white" />
                      </Fab>
                    </Grid>
                    <Grid item>
                      <Fab
                        color="primary"
                        aria-label="add"
                        disabled={currentNumber >= list.length}
                        onClick={() => handleCountChange("increase")}
                      >
                        <AddIcon />
                      </Fab>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <p style={{ textAlign: "center", paddingTop: "10px" }}>
              {searchInput
                ? "No one with this name assigned in the queue."
                : "No one is assigned in queue."}
            </p>
          )}
        </>
      ) : (
        <CircularProgress className={classes.loader} color="primary" />
      )}
    </div>
  );
};

export default AnalyticsPage;
