import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            position: 'absolute',
            // top: -65,
            justifyContent: "center",
            left: "50%",
            transform: "translate(-50%, 30%)",
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
    }),
);

function SimpleAlerts(props) {
    const classes = useStyles();
    const { message, setAlert, type } = props;

    const closeAlert = () => {
        setAlert({ message: "", type: "" });
    };

    return (
        <div className={classes.root}>
            <Alert severity={type}
                action={
                    typeof closeAlert === 'function' ?
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={closeAlert}
                            tabIndex={1}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                        : null
                }
            >{message ? message : ''}</Alert>
        </div>
    );
}

SimpleAlerts.defaultProps = {
    title: '',
    closeErrorMessage: undefined
}

export default React.memo(SimpleAlerts);