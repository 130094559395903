import React from "react";
import { Box, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useStyles from "../assests/css/styles";
import Logo from "../assests/images/habilelabs_full_logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import Demo from "./Menu/SideNavigation";
export function Header() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  return (
    <Box className={classes.rootLogo}>
      {/* <IconButton
        component="span"
        onClick={() => {
          history.goBack();
        }}
      >
        <ChevronLeftIcon />
      </IconButton> */}
      {/* <img src={Logo} alt="logo" onClick={() => history.push("/")} /> */}
      {/* <MenuIcon/> */}
      <div style={{width:"100%",display:"flex"}}>
        <div style={{width:"45%",alignItems:"center",display:"flex"}}>
          <Demo />
        </div>
        <div style={{width:"55%",display:"flex"}}>
          <img src={Logo} alt="logo"  onClick={() => history.push("/")}  />
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "spaceBetween",
          width: "50%",
          alignItems: "center",
          border: "1px solid red",
        }}
      >
        <Demo />
        <img src={Logo} alt="logo" />
      </div>
      <div
        style={{
          // display: "flex",
          // flexDirection: "row",
          // justifyContent: "spaceBetween",
          width: "50%",
          // alignItems: "center",
          border: "1px solid red",
        }}
      >
      </div> */}
    </Box>
  );
}
