import { TextField, Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import useStyles from "../../assests/css/authStyles";
import { ReactComponent as KeyIcon } from "../../assests/icon/ForgotPassword.svg";
import CButton from "./CButton";
import CDiv from "./CDiv";
import { Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";
import CAlert from "../CAlert";
import AuthPageImage from "./AuthPageImage";

const ForgotPassword = (props) => {
  const classes = useStyles();
  const { formState, authState, onStateChange, updateFormState } = props;
  const { control, handleSubmit, reset } = useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });

  const onSubmit = async (data) => {
    // Send confirmation code to user's email
    setIsDisabled(true);
    try {
      updateFormState({ ...formState, email: data.email });
      await Auth.forgotPassword(data.email);
      onStateChange("forgotPasswordSubmit");
      setIsDisabled(false);
    } catch (error) {
      setAlert({ message: error.message, type: "error" });
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    reset();
  }, [authState]);

  return authState === "forgotPassword" ? (
    <>
      <Box
        component="form"
        noValidate
        // onSubmit={handleSubmit(onSubmit)}
        className={classes.root}
      >
        {alert.message !== "" && (
          <CAlert
            message={alert.message}
            type={alert.type}
            setAlert={setAlert}
          />
        )}
        <Grid container className={classes.containerHeight}>
          <AuthPageImage />
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box className={classes.VerificationContainer}>
              <Box className={classes.verificationHeader}>
                <KeyIcon />
                <Typography align="center" mt={5}>
                  Forgot Password
                </Typography>
                <Typography
                  align="center"
                  m={4}
                  className="forgotPasswordTitle"
                >
                  Enter your email id to get OTPs
                </Typography>
              </Box>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                className={classes.root}
              // mt={6}
              >
                {/* {alert.message !== "" && (
                  <CAlert
                    message={alert.message}
                    type={alert.type}
                    setAlert={setAlert}
                  />
                )} */}
                <CDiv>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Email cannot be empty",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        id="outlined-error-helper-text"
                        label="Email"
                        variant="outlined"
                        value={value}
                        placeholder="Email"
                        onChange={onChange}
                        autoComplete="off" //written intentionally to avoid autoComplete on email as well
                        error={!!error}
                        helperText={error ? error.message : " "}
                      />
                    )}
                  />
                  <CButton
                    style={{ width: "60%" }}
                    variant="contained"
                    type="submit"
                    disabled={isDisabled}
                  >
                    Get OTP
                  </CButton>
                  <Typography
                    className={
                      classes.signInFooter + " " + classes.signInFooterInverse
                    }
                    style={{ color: "black" }}
                    align="center"
                    mt={2}
                  >
                    Back to{" "}
                    <span
                      onClick={() => {
                        setAlert({ message: "", type: "" });
                        onStateChange("signIn");
                      }}
                      style={{ color: "#58abe4" }}
                    >
                      Sign In
                    </span>
                  </Typography>
                </CDiv>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  ) : null;
};

export default ForgotPassword;
