import React from 'react'; 

import PageError from './PageError';

class ErrorBoundary extends React.Component {
  
    // Constructor for initializing Variables etc in a state
    // Just similar to initial line of useState if you are familiar 
    // with Functional Components
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    // This method is called if any error is encountered
    componentDidCatch(error, errorInfo) {
    
      // Catch errors in any components below and
      // re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
    
      // You can also log error messages to an error
      // reporting service here
    }
    
    // This will render this component wherever called
    render() {
      if (this.state.errorInfo) {
    
        // Error path
        return (
          <div>
          <PageError title="404" subtitle="Page Not Found" image="../assests/images/404.svg" ></PageError>
          </div>
        );
      }
      // Normally, just render children, i.e. in 
      // case no error is Found
      return this.props.children;
    }
  }

  export default ErrorBoundary;
