import { makeStyles } from "@material-ui/core/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		padding: "30px 20px",
		width: "85%",
		maxWidth: 350,
		margin: "auto",
		background: "#FFFFFF",
		backdropFilter: "blur(2px)",
		borderRadius: 20,
	},
}));

const CDiv = (props) => {
	const classes = useStyles();

	return (
		<div {...props} className={classes.root} >
			{props.children}
		</div>
	);
};

export default CDiv;
