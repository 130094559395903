import React from "react";

import { Box, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useHistory } from "react-router-dom";
import useStyles from "../assests/css/styles";
import Logo from "../assests/images/habilelabs_full_logo.svg";
// import Demo from '../../src/component/Menu/demo'

export function Header() {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box className={classes.rootLogo}>
      {/* <IconButton
        component="span"
        onClick={() => {
          history.goBack();
        }}
      >
        <ChevronLeftIcon />
      </IconButton> */}
      {/* <Demo/> */}

      {/* <img src={Logo} alt="logo" onClick={() => history.push("/")} /> */}
      <img src={Logo} alt="logo" />
    </Box>
  );
}
