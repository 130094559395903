import React from "react";
import GroupIcon from "../../assests/images/Group.png";
import { Typography, Grid } from "@material-ui/core";
import useStyles from "../../assests/css/SignInCss";

const AuthPageImage = (props) => {
  const classes = useStyles();

  return (
    // <div>
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={6}
      className={classes.ImagebackgroundColor}
      alignItems="center"
      justifyContent="center"
      style={{ display: "flex" }}
    >
      {props.PageName ? (
        <Typography className={classes.PageSectionHeading}>
          {props.PageName}
        </Typography>
      ) : null}

      <img
        src={GroupIcon}
        style={{ width: "75%" }}
        className={classes.imageStyle}
        alt="fireSpot"
      />
    </Grid>
    // </div>
  );
};

export default AuthPageImage;
