import { makeStyles } from "@material-ui/core/styles";
import { colorStyles } from "../../assests/css/color";

const useStyles = makeStyles((theme) => ({
  PageSectionHeading: {
    position: "absolute",
    left: 0,
    color: "white",
    alignSelf: "flex-end",
    margin: "20px",
    display: "none",
    fontSize: "32px",
    fontWeight: "bolder",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      // backgroundColor: theme.palette.primary.main,
    },
  },
  root: {
    position: "relative",
    height: "100vh",
  },
  signInContainer: {
    width: "100%",
    display: "flex",
    height: "100%",
    // [theme.breakpoints.down("xs")]: {
    //   height: "74vh",
    // },
  },
  signInHeader: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
    },
  },
  signIn: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0",
    width: "80%",
    maxWidth: 350,
    margin: "auto",
    background: "#FFFFFF",
    backdropFilter: "blur(2px)",
    borderRadius: 20,
    "& .MuiInputBase-root": {
      padding: "14px 10px",
      "& > div": {
        flex: "10%",
      },
      "& > input": {
        padding: "0 0 0 10px",
        color: "#000",
        fontSize: 18,
        "&::placeholder": {
          color: "#000",
          fontSize: 18,
        },
        "&:-webkit-autofill": {
          "-webkit-background-clip": "text",
        },
      },
      "&:before": {
        borderBottom: "1px solid #E8E8E8",
      },
    },
    "& svg": {
      fill: "#2E8BF7",
    },
    "& .MuiFormHelperText-root": {
      // paddingLeft: 25,
    },
    "& .MuiBox-root": {
      "& > p": {
        fontSize: 14,
        color: "#3F83F8",
        marginTop: 10,
        [theme.breakpoints.down("xs")]: {
          marginTop: 0,
        },
      },
    },
    "& .MuiTextField-root": {
      marginBottom: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  hover: {
    color: colorStyles.secondaryColor,
    "&:hover": {
      cursor: "pointer",
    },
  },
  signInFooter: {
    color: "#FFFFFF",
    fontSize: 14,
    marginTop: 20,
    "& span": {
      fontWeight: 600,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },

  signUpFooter: {
    color: "black",
    fontSize: 14,
    marginTop: 20,
    "& span": {
      fontWeight: 600,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  signUpSpan: {
    fontSize: 14,
    color: colorStyles.secondaryColor,
  },

  ImagebackgroundColor: {
    display: "flex",
    backgroundColor: colorStyles.secondaryColor,
    // "& svg": {
    //   fill: "red",
    // },
  },
  containerHeight: {
    height: "100vh",
  },
  imageStyle: {
    // padding: "20px",
    width: "100%",
    backgroundColor: colorStyles.secondaryColor,
    maxHeight: "100%",
  },
  HeadingText: {
    fontSize: "36px",
    marginBottom: "25px",
    display: "block",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
      // backgroundColor: theme.palette.primary.main,
    },
  },
  BottomTextPosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px",
  },
}));
export default useStyles;
