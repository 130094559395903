import React, { useEffect, useState } from "react";
import useStyles from "../../assests/css/authStyles";
import { Auth, Hub } from "aws-amplify";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ConfirmSignUp from "./ConfirmSignUp";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordSubmit from "./ForgotPasswordSubmit";


const initialFormState = {
	username: "",
	password: "",
	email: "",
	authCode: "",
};

const AuthComponent = (props) => {
	const classes = useStyles();
	const { authState, onStateChange } = props;
	const [formState, updateFormState] = useState(initialFormState);
	const [user, updateUser] = useState(null);

	useEffect(() => {
		checkUser();
		setAuthListener();
	}, []);

	const setAuthListener = async () => {
		Hub.listen("auth", (data) => {
			switch (data.payload.event) {
				case "signOut":
					onStateChange("signIn");
					break;
				default:
					break;
			}
		});
	};

	const checkUser = async () => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			updateUser(user);
			updateFormState({ ...formState, formType: "signedIn" });
		} catch (err) {
			return;
		}
	};

	const onChange = (event) => {
		updateFormState({
			...formState,
			[event.target.name]: event.target.value,
		});
	};

	const authProps = {
		formState,
		authState,
		onStateChange,
		onChange,
		updateFormState,
	};

	return (
		authState !== 'signedIn'
			? <div className={classes.authContainer} >
				{authState === "signIn" || authState === "signUp" ? (
					<div
					// className={classes.heroImage}
					>
						{/* <Clouds /> */}
						{/* <PeopleWaiting /> */}
					</div>
				) : null}
				<SignIn {...authProps} />
				<SignUp {...authProps} />
				<ConfirmSignUp {...authProps} />
				<ForgotPassword {...authProps} />
				<ForgotPasswordSubmit {...authProps} />
			</div>
			: null
	);
};
export default AuthComponent;
