import React from "react";
import "./LoadingSpinner.css";

export default function LoadingSpinner({ style }) {
  return (
    <div className="spinner-container" style={style}>
      <div className="loading-spinner"></div>
    </div>
  );
}
