import { InputAdornment, Typography, Grid } from "@material-ui/core";
import { IconButton, TextField } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
// import useStyles from "../../assests/css/authStyles";
import CButton from "./CButton";
import { useForm, Controller } from "react-hook-form";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useStyles from "../../assests/css/SignInCss";
import AuthPageImage from "./AuthPageImage";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     position: "relative",
//     height: "100vh",
//   },
//   signInContainer: {
//     width: "100%",
//     display: "flex",
//     height: "100%",
//     // [theme.breakpoints.down("xs")]: {
//     //   height: "74vh",
//     // },
//   },
//   signInHeader: {
//     color: "#FFFFFF",
//     fontSize: 18,
//     fontWeight: 600,
//     marginBottom: 10,
//     [theme.breakpoints.down("xs")]: {
//       marginBottom: 40,
//     },
//   },
//   signIn: {
//     display: "flex",
//     flexDirection: "column",
//     padding: "20px 0",
//     width: "80%",
//     maxWidth: 350,
//     margin: "auto",
//     background: "#FFFFFF",
//     backdropFilter: "blur(2px)",
//     borderRadius: 20,
//     "& .MuiInputBase-root": {
//       padding: "10px 10px",
//       "& > div": {
//         flex: "10%",
//       },
//       "& > input": {
//         padding: "0 0 0 10px",
//         color: "#000",
//         fontSize: 18,
//         "&::placeholder": {
//           color: "#000",
//           fontSize: 18,
//         },
//         "&:-webkit-autofill": {
//           "-webkit-background-clip": "text",
//         },
//       },
//       "&:before": {
//         borderBottom: "1px solid #E8E8E8",
//       },
//     },
//     "& svg": {
//       fill: "#2E8BF7",
//     },
//     "& .MuiFormHelperText-root": {
//       paddingLeft: 25,
//     },
//     "& .MuiBox-root": {
//       "& > p": {
//         fontSize: 14,
//         color: "#3F83F8",
//         marginTop: 10,
//         [theme.breakpoints.down("xs")]: {
//           marginTop: 0,
//         },
//       },
//     },
//   },
//   hover: {
//     color: colorStyles.secondaryColor,
//     "&:hover": {
//       cursor: "pointer",
//     },
//   },
//   signInFooter: {
//     color: "#FFFFFF",
//     fontSize: 14,
//     marginTop: 20,
//     "& span": {
//       fontWeight: 600,
//       "&:hover": {
//         cursor: "pointer",
//       },
//     },
//   },
//   ImagebackgroundColor: {
//     display: "flex",
//     backgroundColor: colorStyles.secondaryColor,
//     // "& svg": {
//     //   fill: "red",
//     // },
//   },
//   containerHeight: {
//     height: "100vh",
//   },
//   imageStyle: {
//     backgroundColor: colorStyles.secondaryColor,
//     maxHeight: "100%",
//   },
//   HeadingText:{
// 	fontSize: "36px", marginBottom: "25px"
//   },
//   BottomTextPosition:{
// 	display: "flex",
// 	alignItems: "center",
// 	justifyContent: "space-between",
// 	marginTop: "20px",
//   }
// }));
const SignIn = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const { reset, handleSubmit, control } = useForm();
  const { authState, onStateChange } = props;
  const classes = useStyles();
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    reset();
    setAlert({ message: "", type: "" });
  }, [authState]);

  // const closeAlert = () => {
  //   setAlert({ message: "", type: "" });
  // };

  const onSubmit = async (data) => {
    setIsDisabled(true);
    try {
      await Auth.signIn(data.email, data.password);
      // onStateChange("signedIn");
      setIsDisabled(false);
    } catch (error) {
      console.log("error.message", error.message);
      setAlert({ message: error.message, type: "error" });
      setTimeout(() => {
        setAlert({ message: "", type: "" })
      }, 2500)
      setIsDisabled(false);
    }
  };

  return authState === "signIn" ? (
    <Box
      component="form"
      noValidate
      className={classes.root}
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* {alert.message !== "" && ( */}
      {/* <CAlert message={alert.message} type={alert.type} setAlert={setAlert} /> */}
      {/* )} */}

      <Grid container className={classes.containerHeight}>
        <AuthPageImage PageName={"Login"} />

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        //   alignItems="center"
        //   justifyContent="center"
        >
          {/* <Item> */}

          <div className={classes.signInContainer} style={{ margin: "auto" }}>
            {/* <Typography className={classes.signInHeader} align="center">
          Welcome to Queue Management
        </Typography> */}
            <div className={classes.signIn}>
              <Typography className={classes.HeadingText}>Login</Typography>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Email cannot be empty",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}  
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  // 		<TextField
                  //   error
                  //   id="outlined-error-helper-text"
                  //   label="Error"
                  //   defaultValue="Hello World"
                  //   helperText="Incorrect entry."
                  //   variant="outlined"
                  // />
                  <TextField
                    id="outlined-error-helper-text"
                    label="Email"
                    // defaultValue="name@domain.com"
                    variant="outlined"
                    autoComplete="on"
                    value={value}
                    onChange={onChange}
                    placeholder="name@domain.com"
                    helperText={error ? error.message : " "}
                    error={!!error}
                  // helperText={error ? error.message : " "}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <UserIcon />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: "Password cannot be empty" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="outlined-error-helper-text"
                    label="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    autoComplete="on"
                    value={value}
                    onChange={onChange}
                    placeholder="Password"
                    helperText={error ? error.message : " "}
                    error={!!error}
                    InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       <KeyIcon />
                      //     </InputAdornment>
                      //   ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {alert.message !== "" && <p style={{ color: "red", marginTop: "-25px", marginBottom: "0px" }}>{alert.message}</p>}
              <Box sx={{ width: "100%" }} margin="auto">
                <CButton
                  type="submit"
                  variant="contained"
                  disabled={isDisabled}
                // style={{ width: "80%" }}
                >
                  LOGIN
                </CButton>
                <div className={classes.BottomTextPosition}>
                  <Typography
                    onClick={() => onStateChange("signUp")}
                  //   align="left"
                  >
                    Not Registered?
                    <span
                      className={classes.hover}
                      style={{ marginLeft: "8px" }}
                    >
                      Sign Up
                    </span>
                  </Typography>

                  <Typography
                    onClick={() => onStateChange("forgotPassword")}
                    //   align="right"
                    className={classes.hover}
                  >
                    Forgot Password?
                  </Typography>
                </div>
              </Box>
            </div>
            {/* <Typography className={classes.signInFooter} align="center">
          Not a member?{" "}
          <span
            onClick={() => {
              setAlert({ message: "", type: "" });
              onStateChange("signUp");
            }}
          >
            Register Now
          </span>
        </Typography> */}
          </div>
          {/* </Item> */}
        </Grid>
      </Grid>
    </Box>
  ) : null;
};
export default SignIn;
