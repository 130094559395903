import { InputAdornment, TextField, Typography, Grid } from "@material-ui/core";
import { Auth } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
// import useStyles from "../../assests/css/authStyles";
import CButton from "./CButton";
import { useForm, Controller } from "react-hook-form";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CAlert from "../CAlert";
import { CContext } from "./CContext";
import { removeUnconfirmedUser } from "../../api";
import useStyles from "../../assests/css/SignInCss";
import AuthPageImage from "./AuthPageImage";

const SignUp = ({ formState, updateFormState, authState, onStateChange }) => {
  const classes = useStyles();
  const { handleSubmit, control, watch, reset } = useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  // const [userName, setuserName] = useState("");
  // const [UserInputpassword, setUserInputpassword] = useState("");
  // const [userInput, setuserInput] = useState({ email: "", password: "" });
  const { userNameFromContext, setuserNameFromContext } =
    React.useContext(CContext);
  const [isAccountExisted, setisAccountExisted] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () =>
    setshowConfirmPassword(!showConfirmPassword);

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    reset();
  }, [authState]);

  const onSubmit = async (data) => {
    setIsDisabled(true);
    const userInputFromForm = {
      email: data.email,
      password: data.password,
    };
    // setuserName(data.email);
    // setUserInputpassword(data.password);
    try {
      await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email, // optional
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });
      onStateChange("confirmSignUp");
      setuserNameFromContext(data.email);
      updateFormState({
        ...formState,
        email: data.email,
        password: data.password,
      });
      setIsDisabled(false);
    } catch (error) {
      console.log(typeof error.message);
      console.log("error.message==>>>>", error.message);
      if (
        error.message.localeCompare(
          "An account with the given email already exists"
        )
      ) {
        console.log("inside error");
        removeUnconfirmedUser(data.email)
          .then((res) => {
            console.log("res@@@@@@@@@@@@", res);
            if (res.status === 200) {
              console.log("removeUnconfirmedUserremoveUnconfirmedUser==>>");
              console.log("userInputFromForm", userInputFromForm);
              onSubmit(userInputFromForm);
            }
            if (res.response.status === 400) {
              console.log("This email is already registered.==>>");
              setAlert({ message: error.message, type: "error" });
            }
          })
          .catch((err) => console.log(err));
      }

      setIsDisabled(false);
    }
  };

  return authState === "signUp" ? (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className={classes.root}
    >
      {alert.message !== "" && (
        <CAlert message={alert.message} type={alert.type} setAlert={setAlert} />
      )}
      <Grid container className={classes.containerHeight}>
        <AuthPageImage PageName={"Sign Up"} />


        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        //   alignItems="center"
        //   justifyContent="center"
        >
          <div className={classes.signInContainer} style={{ margin: "auto" }}>
            <div className={classes.signIn}>
              <Typography className={classes.HeadingText}> Sign up </Typography>

              <Controller
                name="email"
                defaultValue=""
                control={control}
                rules={{
                  required: "Email cannot be empty",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="outlined-error-helper-text"
                    label="Email"
                    variant="outlined"
                    // placeholder="Email"
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    placeholder="name@domain.com"
                    error={!!error}
                    helperText={error ? error.message : " "}
                    inputProps={{
                      autoComplete: "off",
                    }}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <UserIcon />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "Password cannot be empty",
                  minLength: {
                    value: 6,
                    message: "Password must have at least 6 characters",
                  },
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                    message: "Password must be alphanumeric",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="outlined-error-helper-text"
                    label="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    placeholder="Password"
                    error={!!error}
                    helperText={error ? error.message : " "}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputProps={{
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <KeyIcon />
                      //   </InputAdornment>
                      // ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="Confirm Password"
                control={control}
                defaultValue=""
                rules={{
                  required: "Confirm Password cannot be empty",
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="outlined-error-helper-text"
                    label="Confirm Password"
                    variant="outlined"
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    placeholder="Confirm Password"
                    error={!!error}
                    helperText={error ? error.message : " "}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputProps={{
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <KeyIcon />
                      //   </InputAdornment>
                      // ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowConfirmPassword}
                            onMouseDown={handleShowConfirmPassword}
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Box sx={{ width: "100%" }} margin="auto">
                <CButton
                  variant="contained"
                  type="submit"
                  disabled={isDisabled}
                // onClick={() => setAlert({ message: "", type: "" })}
                >
                  Sign Up
                </CButton>
                {/* </Box>
        </div> */}
                <div className={classes.BottomTextPosition}>
                  <Typography className={classes.signUpFooter} align="center">
                    Already have account ?
                    <span
                      onClick={() => {
                        setAlert({ message: "", type: "" });
                        onStateChange("signIn");
                      }}
                      className={classes.signUpSpan}
                      style={{ color: "#58abe4", marginLeft: "10px" }}
                    >
                      Sign In
                    </span>
                  </Typography>
                </div>
              </Box>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  ) : null;
};

export default SignUp;
