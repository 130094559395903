import { makeStyles } from "@material-ui/core/styles";
import { colorStyles } from "../../assests/css/color";

const useStyles = makeStyles((theme) => ({
	passwordInputWidth: {
				"& .MuiFormControl-root":{
			width:"100% "
		}  ,
		
	},
	PageSectionHeading: {
		position: "absolute",
		left: 0,
		color: "white",
		alignSelf: "flex-end",
		margin: "20px",
		display: "none",
		fontSize: "32px",
		fontWeight: "bolder",
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
		[theme.breakpoints.down("sm")]: {
			display: "block",
			// backgroundColor: theme.palette.primary.main,
		},
	},
	root: {
		position: "relative",
		height: "100vh",
	},
	signInContainer: {
		width: "100%",
		display: "flex",
		height: "100%",
		// [theme.breakpoints.down("xs")]: {
		//   height: "74vh",
		// },
	},
	signInHeader: {
		color: "#FFFFFF",
		fontSize: 18,
		fontWeight: 600,
		marginBottom: 10,
		[theme.breakpoints.down("xs")]: {
			marginBottom: 40,
		},
	},
	signIn: {
		display: "flex",
		flexDirection: "column",
		padding: "20px 0",
		width: "80%",
		maxWidth: 350,
		margin: "auto",
		background: "#FFFFFF",
		backdropFilter: "blur(2px)",
		borderRadius: 20,
		"& .MuiInputBase-root": {
			padding: "10px 10px",
			"& > div": {
				flex: "10%",
			},
			"& > input": {
				padding: "0 0 0 10px",
				color: "#000",
				fontSize: 18,
				"&::placeholder": {
					color: "#000",
					fontSize: 18,
				},
				"&:-webkit-autofill": {
					"-webkit-background-clip": "text",
				},
			},
			"&:before": {
				borderBottom: "1px solid #E8E8E8",
			},
		},
		"& svg": {
			fill: "#2E8BF7",
		},
		"& .MuiFormHelperText-root": {
			// paddingLeft: 25,
		},
		"& .MuiBox-root": {
			"& > p": {
				fontSize: 14,
				color: "#3F83F8",
				marginTop: 10,
				[theme.breakpoints.down("xs")]: {
					marginTop: 0,
				},
			},
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	hover: {
		color: colorStyles.secondaryColor,
		"&:hover": {
			cursor: "pointer",
		},
	},
	signInFooter: {
		color: "#FFFFFF",
		fontSize: 14,
		marginTop: 20,
		"& span": {
			fontWeight: 600,
			"&:hover": {
				cursor: "pointer",
			},
		},
	},

	signUpFooter: {
		color: "black",
		fontSize: 14,
		marginTop: 20,
		"& span": {
			fontWeight: 600,
			"&:hover": {
				cursor: "pointer",
			},
		},
	},
	signUpSpan: {
		fontSize: 14,
		color: colorStyles.secondaryColor
	},

	ImagebackgroundColor: {
		display: "flex",
		backgroundColor: colorStyles.secondaryColor,
		// "& svg": {
		//   fill: "red",
		// },
	},
	containerHeight: {
		height: "100vh",
	},
	imageStyle: {
		// padding: "20px",
		width: "100%",
		backgroundColor: colorStyles.secondaryColor,
		maxHeight: "100%",
	},
	HeadingText: {
		fontSize: "36px",
		marginBottom: "25px",
		display: "block",

		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
			// backgroundColor: theme.palette.primary.main,
		},
	},
	BottomTextPosition: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: "20px",
	},






	root: {
		position: "relative",
	},
	modal: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 330,
		backgroundColor: "#0386ee",
		boxShadow: 24,
		borderRadius: 10,
		textAlign: "center",
		"& .MuiInputBase-input": {
			color: "#000 !important",
		},
		"& Input": {
			// display: "none",
		},
		"& .css-i4bv87-MuiSvgIcon-root": {
			color: "#fff",
		},
		"& img": {
			marginTop: "20px",
			borderRadius: "50%",
		},
	},
	changePassword: {
		color: "#fff",
		padding: "20px 0px 0px 0px",
		backgroundColor: "white",
		textAlign: "center",
		"& .MuiFormControl-root": {
			padding: 10,
		},
		"& p": {
			textAlign: "center",
			color: "black",
			paddingBottom: 20,
		},
	},
	modalFooter: {
		// display: "flex",
		// flexDirection: "row-reverse",
		backgroundColor: "white",
		padding: "10px",
		borderRadius: "0px 0px 10px 10px",
	},
	modalFooter1: {
		display: "flex",
		flexDirection: "row-reverse",
		// backgroundColor: "white",
		// padding: "10px",
		// borderRadius: "0px 0px 10px 10px",
	},
	authContainer: {
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down("xs")]: {
			// gap: "2em",
		},
	},
	heroImage: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingTop: "1em",
		paddingBottom: 0,
		[theme.breakpoints.down("xs")]: {
			padding: "3em 0",
		},
	},
	signInContainer: {
		height: "57vh",
		[theme.breakpoints.down("xs")]: {
			height: "74vh",
		},
	},
	signInHeader: {
		color: "#FFFFFF",
		fontSize: 18,
		fontWeight: 600,
		marginBottom: 10,
		[theme.breakpoints.down("xs")]: {
			marginBottom: 40,
		},
	},
	signInFooter: {
		color: "#FFFFFF",
		fontSize: 14,
		marginTop: 20,
		"& span": {
			fontWeight: 600,
			"&:hover": {
				cursor: "pointer",
			},
		},
	},
	signInFooterInverse: {
		color: "#2B8CF7",
	},
	
	signIn: {
		display: "flex",
		flexDirection: "column",
		padding: "20px 0",
		width: "80%",
		maxWidth: 350,
		margin: "auto",
		background: "#FFFFFF",
		backdropFilter: "blur(2px)",
		borderRadius: 20,
		"& .MuiInputBase-root": {
			padding: '10px 25px',
			"& > div": {
				flex: "10%",
			},
			"& > input": {
				padding: "0 0 0 10px",
				color: "#000",
				fontSize: 18,
				"&::placeholder": {
					color: "#000",
					fontSize: 18,
				},
				"&:-webkit-autofill": {
					"-webkit-background-clip": "text",
				},
			},
			"&:before": {
				borderBottom: '1px solid #E8E8E8',
			},
		},
		"& svg": {
			fill: "#2E8BF7",
		},
		"& .MuiFormHelperText-root": {
			paddingLeft: 25,
		},
		"& .MuiBox-root": {
			"& > p": {
				fontSize: 14,
				color: "#3F83F8",
				marginTop: 10,
				[theme.breakpoints.down("xs")]: {
					marginTop: 0,
				},
			},
		},
	},
	signInButton: {
		paddingTop: 45,
	},
	signUp: {
		display: "flex",
		flexDirection: "column",
		padding: 20,
		width: "80%",
		maxWidth: 350,
		margin: "auto",
		background: "#FFFFFF",
		backdropFilter: "blur(2px)",
		borderRadius: 20,
		"& svg": {
			fill: "#2E8BF7",
		},
		"& > p": {
			fontSize: 14,
			color: "#3F83F8",
		},
	},
	verifyOTP: {


		"& .MuiTypography-alignCenter": {
			textAlign: "start"
		},



		width: "max-content",
		padding: "30px 20px",
		background: "#FFFFFF",
		borderRadius: 20,
		margin: "auto",
		"& .title": {
			fontSize: 18,
			marginBottom: 30,
			fontWeight: "bolder",
		},
		"& .subTitle": {
			width: "75%",
			// margin: "auto",
		},
		"& .otpContainer": {
			margin: "auto",
			padding: "25px 0",
			position: "relative",
			"& > div": {
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
				margin: "auto",
			},
			"& > p": {
				color: "#d32f2f",
				margin: 0,
				bottom: 0,
				position: "absolute",
				fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
				fontWeight: '400',
				fontSize: '0.75rem',
				left: "10%",
			}
		},
		"& .otpInput": {
			width: "35px !important",
			height: 38,
			background: "#F5F5F5",
			border: "1px solid #2B8CF7",
			borderRadius: 0,
			"&:focus-visible": {
				outline: "2px solid #2B8CF7",
			},
		},
		"& .otpErrorState": {
			border: "1px solid red",
			"&:focus-visible": {
				border: "none",
			},
		},
		"& .actionButtons": {
			display: "flex",
			flexDirection: "column",
			gap: 20,
			[theme.breakpoints.down("xs")]: {
				gap: 0,
			},
		},
		[theme.breakpoints.down("xs")]: {
			minHeight: "70%",
			// position: "absolute",
			width: "100%",
			borderTopLeftRadius: "20px",
			borderTopRightRadius: "20px",
			borderBottomLeftRadius: "0",
			borderBottomRightRadius: "0",
			// bottom: "0",
		},
		"& button": {
			width: "70%",
		},
	},
	verificationContainer: {
		display: "flex",
		flexDirection: "column",
		// alignItems: "center",
	},
	verificationHeader: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginTop: 40,
		"& > p": {
			color: "black",
			fontSize: 18,
			fontWeight: 600,
		},
		"& .forgotPasswordTitle": {
			fontSize: 18,
			color: "black",
			fontWeight: 400,
		},
		// "& > svg": {
		// 	scale: "0.5",
		// },
	},
	hover: {
		"&:hover": {
			cursor: "pointer"
		}
	}
}));

export default useStyles;
