import React, { useState, useEffect } from "react";
import useStyles from "../assests/css/styles";
import {
  CircularProgress,
  ListItem,
  ListItemText,
  Box,
  ListItemIcon,
  Grid,
} from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { checkDeviceIP, getDeviceAndQueueList } from "../api";
import { getUserInfo } from "../services/getUserInfo";
import { ReactSVG } from "react-svg";
import deviceNotFound from "../assests/icon/Device-not-found.svg";
import qrScan from "../assests/icon/qr.png";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import { CModal } from ".";
import { Button } from "@aws-amplify/ui-react";
import { Header } from "./HeaderWithoutBack";

const BillBoardScreen = (props) => {
  const history = useHistory();

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const [checkIPResponse, setCheckIPResponse] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => history.push("/");
  return (
    <div className={classes.main}>
      <Header />
      {/* <div style={{textAlign:"center"}}>
        <p>Goto settings and Please connect to WIFI</p>
        <p>WiFi : BillBoard_QMD</p>
        <p>Password: 87654321</p>
      </div> */}
      <div style={{ marginTop: "110px"}}>
        <CModal open={Boolean(true)} handleClose={handleClose}>
          <div style={{ marginTop: "46px" }}>
            <Box>
              <img src={qrScan} alt="QR code" />
            </Box>
            <p style={{ fontSize: 18 }}>Go to settings and Please connect to</p>
            <p style={{ fontSize: 18 }}>WiFi : BillBoard_QMD</p>
            <p style={{ fontSize: 18 }}>Password: 87654321</p>
            <Button
              onClick={() => history.push("/")}
              style={{
                backgroundColor: "#060543",
                color: "white",
                marginTop: "22px",
              }}
            >
              Go to Home
            </Button>
          </div>
        </CModal>
      </div>
    </div>
  );
};

export default BillBoardScreen;

// import React, { useState, useEffect } from "react";
// import useStyles from "../assests/css/styles";
// import {
//   CircularProgress,
//   ListItem,
//   ListItemText,
//   Box,
//   ListItemIcon,
//   Grid,
// } from "@material-ui/core";
// import { checkDeviceIP, getDeviceAndQueueList } from "../api";
// import { getUserInfo } from "../services/getUserInfo";
// import { ReactSVG } from "react-svg";
// import deviceNotFound from "../assests/icon/Device-not-found.svg";
// import qrScan from "../assests/icon/qr.png";
// import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
// import { CModal } from ".";
// import { Button } from "@aws-amplify/ui-react";
// import { Header } from "./Header";

// const BillBoardScreen = (props) => {
//   const classes = useStyles();
//   const [isLoading, setIsLoading] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [deviceName, setDeviceName] = useState('');
//   const [deviceList, setDeviceList] = useState([]);
//   const [checkIPResponse, setCheckIPResponse] = useState(false);
//   const [open, setOpen] = useState(false);

//   const handleClose = () => setOpen(false);

//   useEffect(() => {
//     getUserDetails();
//   }, []);

//   useEffect(() => {
//     const checkInterval = setInterval(async () => {
//       if (!checkIPResponse && !loading && open) {
//         setLoading(true);
//         checkDeviceIP()
//           .then((res) => {
//             if (res.code === "ERR_NETWORK") {
//               setLoading(false);
//               setCheckIPResponse(true);
//             } else setLoading(false);
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//       }
//     }, 1000);
//     return () => clearInterval(checkInterval);
//   });

//   useEffect(() => {
//     if (checkIPResponse) {
//       // window.open(`http://192.168.4.1?name=${name}`, "_blank");
//     }
//   });


//   const handleDirect = () => {
//     window.open(`http://192.168.4.1?name=${open}`, "_blank");
//   }

//   const getUserDetails = async () => {
//     const details = await getUserInfo();
//     // setUserDetails(details);
//     const userName = details.email && details.email.split("@")[0];
//     setDeviceName(userName)
//     fetchDeviceAndQueueList(userName);
//   };

//   const fetchDeviceAndQueueList = (user) => {
//     setIsLoading(true);
//     getDeviceAndQueueList(user)
//       .then((res) => {
//         setIsLoading(false);
//         setDeviceList(res);
//       })
//       .catch((err) => {
//         setIsLoading(false);
//         console.log(err.message);
//       });
//   };

//   const handleListClick = (queue, device) => {
//     const idHash = `${deviceName}_${device}_${queue.replace('.json', "").replace('.json', "")}`
//     // // history.push(`/device-setting/${idHash}`)
//     setOpen(idHash);
//   };

//   return (
//     <div className={classes.main}>
//       <Header />
//       {!isLoading ? (
//         <div style={{ margin: "12px" }}>
//           {deviceList && deviceList.length ? (
//             <Grid container spacing={2}>
//               {
//                 deviceList.map((list, index) => {
//                   const device = list.split("_")[1];
//                   const queue = list.split("_")[2];
//                   return (
//                     <Grid item xs={12} md={6} lg={4} sm={6}>
//                       <ListItem
//                         className={classes.listName}
//                         component="div"
//                         key={index}
//                       >
//                         <ListItemIcon
//                           onClick={() => handleListClick(queue, device)}
//                           style={{ borderRight: "1px solid #D8D8D8" }}
//                         >
//                           <SettingsRemoteIcon color="primary" style={{ height: '90px', marginRight: "20px" }} />
//                         </ListItemIcon>
//                         <ListItemText
//                           onClick={() => handleListClick(queue, device)}
//                           primary={queue.replace(".json", "")}
//                         />
//                         {/* <ListItemIcon onClick={() => handleListClick(queue, device)}>
//                         <ChevronRightIcon color="primary" />
//                       </ListItemIcon> */}
//                       </ListItem>
//                     </Grid>
//                   );
//                 })
//               }
//             </Grid>
//           ) : (
//             <Box className={classes.noDevice}>
//               <ReactSVG src={deviceNotFound} />
//               <p>Device not found.</p>
//             </Box>
//           )}
//         </div>
//       ) : (
//         <CircularProgress className={classes.loader} color="primary" />
//       )}
//       <CModal open={open} handleClose={handleClose}>
//         <div>
//           <Box>
//             <img style={{ width: '90px' }} src={qrScan} alt="QR code" />
//           </Box>
//           <p>Please Connect QMS Device with default password <b>password</b></p>
//           <p>Make sure your mobile internet is off!</p>
//           <p>Now click the Button to Connect</p>
//           <Button onClick={handleDirect} style={{
//             backgroundColor: '#060543', color: 'white',
//             marginTop: '22px'
//           }}>
//             Connect
//           </Button>
//         </div>
//       </CModal>
//     </div>
//   );
// };

// export default BillBoardScreen;
