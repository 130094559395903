import { Typography, Grid, Button } from "@material-ui/core";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
// import useStyles from "../../assests/css/authStyles";
import CButton from "../component/auth-components/CButton";
import { useForm, Controller } from "react-hook-form";
import { Box } from "@mui/system";

import useStyles from "../assests/css/SignInCss";
import GroupIcon from "../assests/images/Group.png";
import defaultImage from "../assests/images/default-profile.png";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { CModal, CroppieModal } from "../component/";

import CAlert from "../component/CAlert";
import { uploadImageName } from "../api";
import { CContext } from "../component/auth-components/CContext";

const bckStyles = {
  paperContainer: {
    height: 1356,
    backgroundImage: `url(${"../assests/images/Group.svg"})`,
  },
};
const ProfileSetup = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const { reset, handleSubmit, control } = useForm();

  const { authState } = props;

  const { udpateValue } = props;

  const classes = useStyles();
  // const handleClickShowPassword = () => setShowPassword(!showPassword);
  // const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [nameValidationError, setnameValidationError] = useState("");
  // const [phoneValidationError, setPhoneValidationError] = useState("");

  const [userNameNew, setUserNameNew] = React.useState("");
  // const [phone, setPhone] = React.useState("");
  // const [imageToupload, setimageToupload] = React.useState("");
  const [img, setImg] = React.useState("");
  const [imageUpload, setImageUpload] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState(null);
  const [showImageError, setImageShowError] = React.useState(false);
  const [croppedImage, setCroppedImage] = React.useState("");
  const {
    isModalOpen,
    userNameFromContext,
    setuserNameFromContext,
    setisModalOpen,
  } = React.useContext(CContext);

  const handleCloseImageUpload = () => {
    setImageUpload(false);
  };

  const uploadImage = (base64) => {
    if (selectedImg.size > 2500000) {
      handleCloseImageUpload();
      return setImageShowError("Image should be only max 2MB");
    }
    setImageShowError(false);
    setImg(base64);
    localStorage.setItem("img", base64);
    // saveImageAndUserName(userNameNew, base64.split(",")[1]);
    handleCloseImageUpload();
    // convertLength

    // getBase64(base64).then((base64) => {
    //   setImg(base64);
    //   console.log("base64.split(", ")[1]", base64);
    //   localStorage.setItem("img", base64);
    //   saveImageAndUserName(userNameNew, base64.split(",")[1]);
    //   handleCloseImageUpload();
    // });
  };

  const saveNameImageApiCall = () => {
    // const validEmail = /^[a-zA-Z ]*$/;
    // // var result = regex.test(str);
    console.log("saveNameImageApiCall1");
    if (userNameNew == "") {
      setAlert({ message: "Please enter Name", type: "error" });
      setTimeout(() => {
        setAlert({ message: "", type: "" });
      }, 2500);
      setnameValidationError("Please enter Name");
      return;
    }
    // if (!validEmail.test(userNameNew)) {
    //   setnameValidationError("Only Character allowed");
    //   return;
    // }
    if (img == "") {
      setAlert({ message: "Please Select Image", type: "error" });
      setTimeout(() => {
        setAlert({ message: "", type: "" });
      }, 2500);
      setnameValidationError("Please Select Image");
      return;
    }
   
    console.log(userNameNew);
    console.log("saveNameImageApiCall2");

    saveImageAndUserName(
      userNameFromContext.split("@")[0] + "_" + userNameNew,
      img.split(",")[1]
    );
    // setUserNameNew("");
    // setImg("");
    // setisModalOpen(false);
  };
  const saveImageAndUserName = async (name, image) => {
    console.log("saveNameImageApiCall3");

    console.log("uploadImageNameData", name, image);
    const out = await uploadImageName(name, image);
    if (out.status === 200) {
      console.log("saveNameImageApiCall4");

      setisModalOpen(false);
      setUserNameNew("");
      setImg("");
      localStorage.setItem("IsProfileSettingUP", false);
      udpateValue();
      // window.location.reload(false);
    }
    console.log("out", out.status);
  };

  const handleImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        if (file.size > 2500000) {
          return setImageShowError("Image should be only max 2MB");
        }
        setImageUpload(true);
        setImageShowError(false);
        setSelectedImg(file);
      } else {
        return setImageShowError("Only images are allow");
      }
    }
  };

  useEffect(() => {
    reset();
    // localStorage.setItem("IsProfileSettingUP", true);
    setAlert({ message: "", type: "" });
  }, [authState]);

  const closeAlert = () => {
    setAlert({ message: "", type: "" });
  };

  const onSubmit = async (data) => {
    // setIsDisabled(true);
    // try {
    //   await Auth.signIn(data.email, data.password);
    //   // onStateChange("signedIn");
    //   setIsDisabled(false);
    // } catch (error) {
    //   console.log("error.message", error.message);
    //   setAlert({ message: error.message, type: "error" });
    //   setIsDisabled(false);
    // }
  };

  return (
    <Box
      component="form"
      noValidate
      className={classes.root}
      onSubmit={handleSubmit(onSubmit)}
    >
      {alert.message !== "" && (
        <CAlert message={alert.message} type={alert.type} setAlert={setAlert} />
      )}

      <Grid container className={classes.containerHeight}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          className={classes.ImagebackgroundColor}
          alignItems="center"
          justifyContent="center"
          style={{ display: "flex" }}
        >
          {/* <Item> */}
          {/* <div style={{ position: "relative" }}> */}
          <Typography className={classes.PageSectionHeading}>
            Profile
          </Typography>
          {/* <Group className={classes.imageStyle} /> */}
          <img
            src={GroupIcon}
            style={{ width: "75%" }}
            className={classes.imageStyle}
            alt="fireSpot"
          />

          {/* </div> */}

          {/* </Item> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          //   alignItems="center"
          //   justifyContent="center"
        >
          {/* <Item> */}

          <div className={classes.signInContainer} style={{ margin: "auto" }}>
            {/* <Typography className={classes.signInHeader} align="center">
          Welcome to Queue Management
        </Typography> */}
            <div className={classes.signIn}>
              <Typography className={classes.HeadingText}>
              Profile
              </Typography>
              <Box
                className={classes.upperProfile}
                style={{
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <img
                  style={{ borderRadius: "50%" }}
                  src={
                    img != null && img != undefined && img != ""
                      ? img
                      : defaultImage
                  }
                  key={img}
                  name="file"
                  className="img-thumbnail"
                  height={100}
                  width={100}
                  alt="profile"
                />
                <input
                  style={{ display: "none" }}
                  id="selectImage"
                  type="file"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  onChange={handleImg}
                  accept="image/x-png,image/gif,image/jpeg"
                  className={classes.input}
                />
                <Box sx={{ height: "10px" }} />
                <label className={classes.icon} htmlFor="selectImage">
                  {<PhotoCamera />}
                </label>
                {showImageError && (
                  <span style={{ color: "red" }}>{showImageError}</span>
                )}
                <CModal
                  maxWidth="xs"
                  open={imageUpload}
                  handleClose={handleCloseImageUpload}
                  bgClick={true}
                >
                  {console.log("selectedImg", selectedImg)}

                  <CroppieModal
                    image={selectedImg}
                    uploadImage={uploadImage}
                    uploading={false}
                    closeModal={handleCloseImageUpload}
                    modalTitle={"Changeadadadsaprofile picture"}
                    error={showImageError}
                    setImage={setCroppedImage}
                  />
                  <Grid container>
                    <Grid item xs={6} align={"left"}>
                      <div className={classes.cancelBtn}>
                        <Button
                          variant="outlined"
                          onClick={handleCloseImageUpload}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                    {console.log("croppedImage", croppedImage)}

                    <Grid item xs={6} align={"right"}>
                      <div className={classes.uploadBtn}>
                        <Button
                          variant="outlined"
                          disabled={!selectedImg && !showImageError}
                          onClick={() => uploadImage(croppedImage)}
                        >
                          Upload
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </CModal>
              </Box>
              <Box sx={{ height: "20px" }} />
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: "Name cannot be empty",
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="outlined-error-helper-text"
                    label="Your Name"
                    value={userNameNew}
                    onChange={(e) => {
                      setnameValidationError("");
                      setUserNameNew(e.target.value);
                      onChange(e);
                    }}
                    variant="outlined"
                    placeholder="Your Name"
                    helperText={error ? error.message : " "}
                    error={!!error}
                  />
                )}
              />
              <Box sx={{ height: "10px" }} />
              {/* <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{
                    required: {
                      value: true,
                      message: "Phone Number is Required",
                    },
                    minLength: {
                      value: 10,
                      message: "Please enter a valid 10 digit number",
                    },
                    pattern: {
                      value: /^(?:91?|[0]?)?[6789]\d{9}$/,
                      message: "Invalid mobile number",
                    },
                  }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                 
                  <TextField
                    id="outlined-error-helper-text"
                    label="Your Phone"
                    
                    value={phone}
                    onChange={(e) => {
                      setPhoneValidationError("");
                      setPhone(e.target.value);
                      onChange(e);
                    }}
                    minLength="10"
                    variant="outlined"
                    
                    placeholder="Your Phone"
                    helperText={error ? error.message : " "}
                    error={!!error}
                  />
                )}
              /> */}

              <Box sx={{ width: "100%" }} margin="auto">
                <CButton
                  type="submit"
                  onClick={saveNameImageApiCall}
                  variant="contained"
                  disabled={isDisabled}
                  // style={{ width: "80%" }}
                >
                  Save
                </CButton>
              </Box>
            </div>
            {/* <Typography className={classes.signInFooter} align="center">
          Not a member?{" "}
          <span
            onClick={() => {
              setAlert({ message: "", type: "" });
              onStateChange("signUp");
            }}
          >
            Register Now
          </span>
        </Typography> */}
          </div>
          {/* </Item> */}
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProfileSetup;
