import moment from "moment";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const useAuth = (id) => {
  //get item from localstorage

  let user = null;
  const _user = localStorage.getItem(id);
  if (_user !== null) {
    user = JSON.parse(_user);
  }
  if (_user !== null) {

    const assignDate = user.date && user.date.split("/")[0];
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    return {
      auth: assignDate === todayDate ? true : false,
      id: assignDate === todayDate ? user.id : false,
    };
  } else {
    return {
      auth: false,
      role: null,
    };
  }
};


const PrivateRoute = ({ component: Component, ...rest }) => {

  const location = rest.location;
  const id = atob(location.pathname.split("/").pop())
  const { auth } = useAuth(id);

  const redirectUrl = `/token/${btoa(id)}`;
  React.useEffect(() => {
    const userCount = localStorage.getItem(id);
  }, []);
  console.log("useEffectTokenIF",auth)
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? <Redirect to={redirectUrl} /> : <Component {...props} />
      }
    />
  );
};

export default PrivateRoute;

// import React from "react"
// import { Navigate, Outlet } from "react-router-dom"

// const useAuth = () => {
//   //get item from localstorage

//   let user = null

//   const _user = localStorage.getItem("userCount")

//   if (_user) {
//     user = JSON.parse(_user)
//   }
//   if (user) {
//     return {
//       auth: true,
//       role: user.role,
//     }
//   } else {
//     return {
//       auth: false,
//       role: null,
//     }
//   }
// }

// const PrivateRoute = (props) => {
//   const { auth, role } = useAuth()

//   //if the role required is there or not
//   if (props.roleRequired) {
//     return auth ? (
//       props.roleRequired === role ? (
//         <Outlet />
//       ) : (
//         <Navigate to="/denied" />
//       )
//     ) : (
//       <Navigate to="/login" />
//     )
//   } else {
//     return auth ? <Outlet /> : <Navigate to="/token-registration" />
//   }
// }

// export default PrivateRoute
