import React from "react";
import useStyles from "../assests/css/styles";
import { isEqual } from "lodash";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Divider } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { IsVisitedApiHandler } from "../api";
const AnalyticsItem = ({ data, currentNumber }) => {
  console.log("analyticdata", data);
  let DeviceName;
  if (data.length > 0) {
    DeviceName = data[0].Device_Name;
  }
  const [expandedRows, setExpandedRows] = React.useState([]);
  const classes = useStyles();
  const [expandState, setExpandState] = React.useState({});
  const [isVisited, setisVisited] = React.useState(false);
  const [selectedRow, setselectedRow] = React.useState([]);
  const handleEpandRow = (event, userId) => {
    setExpandedRows(userId);

    // const currentExpandedRows = expandedRows;
    // const isRowExpanded = currentExpandedRows.includes(userId);

    // let obj = {};
    // isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    // setExpandState(obj);

    // const newExpandedRows = isRowExpanded
    //   ? currentExpandedRows.filter((id) => id !== userId)
    //   : currentExpandedRows.concat(userId);

    // setExpandedRows(newExpandedRows);
  };
  const handleCloseExpandRow = () => {
    console.log("handleCloseExpandRow")
    setExpandedRows([]);
  };
  const handleIsVisited = (event, data) => {
    console.log("deleteHandler", event.target.checked);
    console.log("deleteHandler", data);
    let apiData = {
      Device_Name: { DeviceName },
      Date_Time: data,
      isVisited: event.target.checked,
    };
    IsVisitedApiHandler(apiData);

    if (event.target.checked === true) {
      setselectedRow((oldArray) => [...oldArray, data]);
    } else {
      setselectedRow(selectedRow.filter((item) => item !== data));
    }
    setisVisited(event.target.checked);
  };
  const handleSelectedState = (data) => {
    // setselectedRow(data)
  };
  const deleteHandler = (data) => {
    // updateList(list.filter(item => item.name !== name))
  };
  // data.map((user) => {
  //   if (user.Is_Visited === "1") {
  //     setselectedRow((oldArray) => [...oldArray, user.Date_Time]);
  //   }
  // });
  React.useEffect(() => {
    data.map((user) => {
      if (user.Is_Visited === "1") {
        setselectedRow((oldArray) => [...oldArray, user.Date_Time]);
      }
    });
  }, [data]);

  console.log("expandedRows", expandedRows);
  return (
    <Container
      style={{ marginTop: "20px", maxWidth: "1000vh", textAlign: "center" }}
    >
      <Row>
        <Col sm={12}>
          <Table style={{ width: "-webkit-fill-available" }}>
            <thead>
              <tr
                className={classes.queueName + " " + classes.headingDark}
                component="div"
              >
                <th>Visited</th>
                <th>Name</th>
                <th>Contact No.</th>
                <th>Queue No.</th>
                <th>Arrival at</th>
                <th></th>
              </tr>
            </thead>
            {/* <Divider /> */}
            <tbody>
              {console.log("analticdata", data)}
              {data &&
                data.map((user) => (
                  <>
                    {console.log("analticdata", user)}

                    <tr
                      key={user.Date_Time}
                      className={
                        selectedRow.indexOf(user.Date_Time) > -1
                          ? classes.queueNameVisited
                          : Number(user.Count) === currentNumber &&
                            Number(user.Count) !== 0
                          ? classes.queueNameCurrent
                          : classes.queueName
                      }
                    >
                      <td>
                        <Checkbox
                          style={{ color: "#0386ee" }}
                          // checked={selectedRow.indexOf(user.Date_Time) > -1 || {user.Is_Visited === 1}}
                          // checked={
                          //   user.Is_Visited === "1" ||
                          //   selectedRow.indexOf(user.Date_Time) > -1
                          // }
                          checked={selectedRow.indexOf(user.Date_Time) > -1}
                          onChange={(event) => {
                            handleIsVisited(event, user.Date_Time);
                            handleSelectedState(user.Date_Time);
                          }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </td>
                      <td>{user.Name}</td>
                      <td>
                        {
                          <a
                            href={`tel:+${user.Contact}`}
                          >{`+${user.Contact}`}</a>
                        }
                      </td>
                      <td>{user.Count == 0 ? "NA" : user.Count}</td>
                      <td>
                        {moment(new Date(user.Date_Time)).format("hh:mm A")}
                      </td>

                      <td style={{ cursor: "pointer" }}>
                        {expandedRows===user.Date_Time ? (
                          <KeyboardArrowUpIcon
                            color="primary"
                            onClick={(event) => handleCloseExpandRow()}
                          />
                        ) : (
                          <ArrowDropDownIcon
                            color="primary"
                            onClick={(event) =>
                              handleEpandRow(event, user.Date_Time)
                            }
                          />
                        )}
                      </td>
                      {/* <td onClick={()=>{deleteHandler()}}>
                        delete
                      </td> */}
                    </tr>
                    <>
                      {expandedRows.includes(user.Date_Time) ? (
                        <tr>
                          <td colspan="6">
                            <div style={{ padding: "10px", textAlign: "left" }}>
                              {console.log("Form_Data", user)}
                              {console.log("Form_Data", typeof user.Form_Data)}

                              <ul>
                                {user.Form_Data &&
                                  Object.entries(user.Form_Data).map(
                                    ([key, value]) => {
                                      if (key !== "notificationId") {
                                        return (
                                          <li>
                                            <span>
                                              <b>{key} </b>
                                            </span>{" "}
                                            {"  "}
                                            <span>{value}</span>
                                          </li>
                                        );
                                      }
                                    }
                                  )}
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  </>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(AnalyticsItem, (prev, next) => isEqual(prev, next));
