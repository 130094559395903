// import React, { useState, useEffect, createContext } from "react";

// export const CContext = createContext();

// const CContextProvider = ({ children }) => {
//   const [prevState, setPrevState] = useState(true);
//   const contextValue = { prevState, setPrevState };
//   return <CContext.Provider value={contextValue}>{children}</CContext.Provider>;
// };

// export default CContextProvider;

import React, { useState, createContext } from "react";

export const CContext = createContext();

const CContextProvider = ({ children }) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  const [userNameFromContext, setuserNameFromContext] = useState("");
  const contextValue = {
    isModalOpen,
    setisModalOpen,
    userNameFromContext,
    setuserNameFromContext,
  };
  return <CContext.Provider value={contextValue}>{children}</CContext.Provider>;
};

export default CContextProvider;
