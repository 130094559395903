import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colorStyles } from "../assests/css/color";
import {
  CircularProgress
} from "@material-ui/core";




const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: colorStyles.primaryBackgroundColor,
    height: 80,
  },
}));
export function HeaderMock() {
  const classes = useStyles();
  const [img, setImg] = React.useState(localStorage.getItem("img"));
  const [isLoading, setIsLoading] = React.useState(false);

  // React.useEffect(() => {
  //   setImg(localStorage.getItem("img"));
  // }, [img]);

  // console.log("img", img)
  const finalimg = localStorage.getItem('img')  

  
  React.useEffect(() => {
    if(finalimg){setImg(localStorage.getItem('img'))
  setIsLoading(false)}

    else{setIsLoading(true)}}, [finalimg]);
  return (
    <div className={classes.header}>
      <div>
        <div
          style={{
            position: "relative",
            width: "100%",
          }}
        >
          <span> {isLoading?(<CircularProgress className={classes.loader} />):

            <img style={{
              height: 65,
              width: 65,
              textAlign: "center",
              marginTop: 7,
              borderRadius: '52px'
            }}
              src={img}
              key={img}
              name="file"
              className="img-thumbnail"
              height={100}
              width={100}
              alt="profile" />
}         </span>
        </div>
      </div>
    </div>
  );
}
